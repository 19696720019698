.contact-details__item {
    display: flex;
    justify-content: flex-start;

    padding: 10px 0;
}

.contact-details__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-top: 2px;
}

.contact-details__text {
    margin-bottom: 0;
}

.footer-nav__item {
    a {
        color: $neutral_color_extra_dark;
        text-decoration: none;

        display: block;
        padding: 10px 0;

        transition: all 0.2s ease-in;

        &:hover {
            color: $neutral_color_dark;
        }
    }
}

.copyright {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    padding-top: 20px;
    border-top: 1px solid $neutral_color_extra_light;


    a {
        color: $secondary_color_dark;
    }
}
