.property-filter {
    @extend .wrapper;
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 100%;
    left: 0;
    z-index: 10;
    background-color: white;
    overflow: scroll;

    transition: all 0.2s ease-in-out;

    &.open {
        transform: translateY(100%);
    }

    .brt-wrapper {
        max-width: 300px;
        margin: 20px auto;
    }
}

@media screen and (min-width:  $large_device) {
    .property-filter {
        position: static;
        overflow: initial;
        padding-top: 0;
        padding-bottom: 12px;

        &.open {
            transform: translateY(0);
        }

        .filter-properties__heading {
            display: none;
        }
    }

    #toggle-property-filter {
        display: none;
    }
}

#close-property-filter {
    @extend .text--small;
    color: $neutral_color;
}


.buy-rent__radio-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form__toggle-group {
    text-align: left;
    padding: 8px 0;
    width: 100%;
    border-bottom: 1px solid $neutral_color_light;
    .button__wrapper {
        height: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .button__icon {
        transition: all 0.2s ease-in;
    }

    & + .toggle__content {
        height: 0;
        overflow: hidden;

    }

    &.open + .toggle__content {
        height: auto;
    }

    &.open .button__icon {
        transform: rotate(180deg);
    }
}

.property-filter .form__select {
    border: none;

    select {
        @extend .text--base;
        padding: 0;
        border-bottom: 1px solid $neutral_color_light;

    }
}
// child select
.toggle__content .form__select select {
    padding-left: 12px;
}
.toggle-tags__wrapper {
    @extend .wrapper;
    padding-top: 0;
    padding-bottom: 0;
}
.filter-tag {
    background-color: $secondary_color_extra_light;
    color: $secondary_color_dark;
    border-radius: 4px;
    margin-right: 12px;
    margin-top: 12px;
    padding: 4px 12px 4px 30px;
    position: relative;

    &:before {
        position: absolute;
        left: 8px;
        top:8px;
        display: block;
        content: "";
        background: url('/assets/icons/close.svg');
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
    }
}


@media screen and (min-width: $large_device) {
    .large-up\:property-filter {
        display: flex;
        justify-content: flex-start;
        background-color: $neutral_color_extra_light;
        padding: 12px;

        .form__toggle-group,
        .form__select select {
            border: none;
            padding: 0;
            height: 45px;

            .button__icon {
                height: 24px;
                margin-left: 10px;
            }
        }
        .input__wrapper {
            border-right: 1px solid $neutral_color_light;
            padding: 0 12px;
            position: relative;
            select {
                padding-right: 30px;
                width: 100%;
            }
            &:last-of-type {
                border-right: none;
            }

            .toggle__content {
                position: absolute;
                top: 50px;
                left: 0;
                z-index: 10;
                min-width: 200px;
                background: white;
                border-radius: 4px;
                box-shadow: 0px 0px 20px rgba(0,0,0,.15);

            }
        }

        .toggle.open + .toggle__content {
            padding: 12px;
        }

        button {
            margin-left: auto;
        }
    }
}
