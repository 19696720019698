.navbar {
    @extend .wrapper;
    padding-top: $grid_gap;
    padding-bottom: $grid_gap;

    display: flex;
    justify-content: space-between;

    position: relative;
}

.navbar .logo {
    display: block;
    max-width: 160px;
}

.main-nav {
    position: absolute;
    top: -100vh;
    left: 0;
    z-index: 10;

    transition: all 0.2s ease-in;

    &.open {
        transform: translateY(100vh);
    }

    @media screen and (min-width: $large_device) {
        position: static;
    }
}

.main-nav__list {
    background-color: #fff;
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    @media screen and (min-width: $large_device) {
        flex-direction: row;
        justify-content: flex-end;
        height: auto;
        width: auto;
    }
}
.menu-open {
    margin-left: auto;

    @media screen and (min-width: $large_device) {
        display: none;
    }
}
.main-nav__item.menu-close {
    @extend .wrapper;
    padding-top: $grid_gap;

    position: absolute;
    top: 0;
    right: 0;

    @media screen and (min-width: $large_device) {
        display: none;
    }
}

.main-nav__item {
    font-size: $text_size_medium;

    @media screen and (min-width: $large_device) {
        margin-right: $grid_gap * 2;
        font-size: $text_size_base;
    }

    a {
        @extend .heading;
        color: $neutral_color_dark;
        text-decoration: none;

        display: block;
        padding: 12px 0;
    }
}

.main-nav__item:last-child {
    @media screen and (min-width: $large_device) {
        margin-right: 0;
    }
}
