.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pagination__item a,
.pagination__item button {
    display: block;
    width: 40px;
    height: 40px;
    margin: 4px;
    text-align: center;
    line-height: 40px;
    color: $neutral_color_dark;
    text-decoration: none;
    border: 1px solid $neutral_color_light;

    &.active {
        background-color: $secondary_color;
        color: $secondary_color_extra_dark;
    }
}
