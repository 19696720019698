.timeline {
    position: relative;
}

@media screen and (min-width: $large_device) {
    .timeline:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
        background-color: $secondary_color;
    }

    .buying-step .grid__item:after {
        display: block;
        content: '';
        width: 35px;
        height: 2px;
        background-color: $secondary_color;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .buying-step:nth-child(even) .grid__item:after {
        right: -20px;
    }
    .buying-step:nth-child(odd) .grid__item:after {
        left: -20px;
    }
}

.buying-process__bg {
    background-color: $neutral_color_extra_light;
}

.buying-step {
    margin-bottom: $grid_gap;

    @media screen and (min-width: $large_device) {
        margin-bottom: $grid_gap * 2;
    }
}

.buying-step__card {
    @extend .shadow;
    background-color: white;
    padding: $grid_gap;
    position: relative;

    @media screen and (min-width: $large_device) {
        display: flex;
        padding: $grid_gap * 2 $grid_gap;
    }
}
.buying-step .grid__item {
    position: relative;
}


.buying-step__count {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    background-color: $primary_color;
    color: white;
    text-align: center;
    line-height: 24px;
}


.buying-step__icon {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    margin-right: 8px;
    margin-bottom: 12px;
}

@media screen and (min-width: $large_device) {
    .buying-step:nth-child(even) .grid__item {
        grid-column: 1 / span 6;
        padding-right: 35px;
    }
    .buying-step:nth-child(odd) .grid__item {
        grid-column: 7 / span 6;
        padding-left: 35px;
    }
}
