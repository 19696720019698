* {
  box-sizing: border-box; }

body {
  margin: 0; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0; }

input, textarea, select {
  margin: 0; }

button {
  border: none;
  outline: none;
  background-color: transparent; }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

img {
  width: 100%; }

/*------------------------------
|                              |
|            CONFIG            |
|                              |
------------------------------*/
/*------------------------------
------------------------------*/
/*------------------------------
COLOURS
------------------------------*/
/*------------------------------
TYPOGRAPHY
------------------------------*/
/*------------------------------
######      WRAPPER       ######
------------------------------*/
.wrapper, .navbar, .main-nav__item.menu-close, .property-filter, .toggle-tags__wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 30px 5%; }
  .wrapper--full-width {
    max-width: 100%; }
  @media screen and (min-width: 1280px) {
    .wrapper, .navbar, .main-nav__item.menu-close, .property-filter, .toggle-tags__wrapper {
      padding: 60px 5%; } }

/*------------------------------
######        GRID        ######
------------------------------*/
.grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(12, 1fr); }
  @media screen and (min-width: 1024px) {
    .grid {
      gap: 40px; } }

/*------------------------------
ALL DEVICES
------------------------------*/
.grid__item--small {
  grid-column: span 2; }
  .grid__item--small-centered {
    grid-column: 6 / span 2; }

.grid__item--medium {
  grid-column: span 6; }
  .grid__item--medium-centered {
    grid-column: 4 / span 6; }

.grid__item--large {
  grid-column: span 8; }
  .grid__item--large-centered {
    grid-column: 3 / span 8; }

.grid__item,
.grid__item--full {
  grid-column: span 12; }

.grid__item--one-twelfth {
  grid-column: span 1; }
  .grid__item--one-twelfth-first {
    grid-column: 1 / span 1;
    grid-row-start: 1; }

.grid__item--two-twelfths {
  grid-column: span 2; }
  .grid__item--two-twelfths-first {
    grid-column: 1 / span 2;
    grid-row-start: 1; }

.grid__item--three-twelfths,
.grid__item--one-forth {
  grid-column: span 3; }
  .grid__item--three-twelfths-first,
  .grid__item--one-forth-first {
    grid-column: 1 / span 3;
    grid-row-start: 1; }

.grid__item--four-twelfths,
.grid__item--one-third {
  grid-column: span 4; }
  .grid__item--four-twelfths-first,
  .grid__item--one-third-first {
    grid-column: 1 / span 4;
    grid-row-start: 1; }

.grid__item--five-twelfths {
  grid-column: span 5; }
  .grid__item--five-twelfths-first {
    grid-column: 1 / span 5;
    grid-row-start: 1; }

.grid__item--six-twelfths,
.grid__item--two-forths,
.grid__item--one-half {
  grid-column: span 6; }
  .grid__item--six-twelfths-first,
  .grid__item--two-forths-first,
  .grid__item--one-half-first {
    grid-column: 1 / span 6;
    grid-row-start: 1; }

.grid__item--seven-twelfths {
  grid-column: span 7; }
  .grid__item--seven-twelfths-first {
    grid-column: 1 / span 7;
    grid-row-start: 1; }

.grid__item--eight-twelfths,
.grid__item--two-thirds {
  grid-column: span 8; }
  .grid__item--eight-twelfths-first,
  .grid__item--two-thirds-first {
    grid-column: 1 / span 8;
    grid-row-start: 1; }

.grid__item--nine-twelfths,
.grid__item--three-forths {
  grid-column: span 9; }
  .grid__item--nine-twelfths-first,
  .grid__item--three-forths-first {
    grid-column: 1 / span 9;
    grid-row-start: 1; }

.grid__item--ten-twelfths {
  grid-column: span 10; }
  .grid__item--ten-twelfths-first {
    grid-column: 1 / span 10;
    grid-row-start: 1; }

.grid__item--eleven--twelfths {
  grid-column: span 11; }
  .grid__item--eleven--twelfths-first {
    grid-column: 1 / span 11;
    grid-row-start: 1; }

.align--top {
  align-self: start; }

.align--center {
  align-self: center; }

.align--bottom {
  align-self: end; }

.justify--left {
  justify-self: start; }

.justify--center {
  justify-self: center; }

.justify--right {
  justify-self: end; }

/*------------------------------
SMALL DEVICES AND UP
------------------------------*/
@media screen and (min-width: 500px) {
  .small-up\:grid__item--small {
    grid-column: span 2; }
    .small-up\:grid__item--small-first {
      grid-column: 1 / span 2;
      grid-row-start: 1; }
    .small-up\:grid__item--small-centered {
      grid-column: 6 / span 2; }
  .small-up\:grid__item--medium {
    grid-column: span 6; }
    .small-up\:grid__item--medium-first {
      grid-column: 1 / span 6;
      grid-row-start: 1; }
    .small-up\:grid__item--medium-centered {
      grid-column: 4 / span 6; }
  .small-up\:grid__item--large {
    grid-column: span 8; }
    .small-up\:grid__item--large-first {
      grid-column: 1 / span 8;
      grid-row-start: 1; }
    .small-up\:grid__item--large-centered {
      grid-column: 3 / span 8; }
  .small-up\:grid__item--full {
    grid-column: span 12; }
  .small-up\:grid__item--one-twelfth {
    grid-column: span 1; }
    .small-up\:grid__item--one-twelfth-first {
      grid-column: 1 / span 1;
      grid-row-start: 1; }
  .small-up\:grid__item--two-twelfths {
    grid-column: span 2; }
    .small-up\:grid__item--two-twelfths-first {
      grid-column: 1 / span 2;
      grid-row-start: 1; }
  .small-up\:grid__item--three-twelfths,
  .small-up\:grid__item--one-forth {
    grid-column: span 3; }
    .small-up\:grid__item--three-twelfths-first,
    .small-up\:grid__item--one-forth-first {
      grid-column: 1 / span 3;
      grid-row-start: 1; }
  .small-up\:grid__item--four-twelfths,
  .small-up\:grid__item--one-third {
    grid-column: span 4; }
    .small-up\:grid__item--four-twelfths-first,
    .small-up\:grid__item--one-third-first {
      grid-column: 1 / span 4;
      grid-row-start: 1; }
  .small-up\:grid__item--five-twelfths {
    grid-column: span 5; }
    .small-up\:grid__item--five-twelfths-first {
      grid-column: 1 / span 5;
      grid-row-start: 1; }
  .small-up\:grid__item--six-twelfths,
  .small-up\:grid__item--two-forths,
  .small-up\:grid__item--one-half {
    grid-column: span 6; }
    .small-up\:grid__item--six-twelfths-first,
    .small-up\:grid__item--two-forths-first,
    .small-up\:grid__item--one-half-first {
      grid-column: 1 / span 6;
      grid-row-start: 1; }
  .small-up\:grid__item--seven-twelfths {
    grid-column: span 7; }
    .small-up\:grid__item--seven-twelfths-first {
      grid-column: 1 / span 7;
      grid-row-start: 1; }
  .small-up\:grid__item--eight-twelfths,
  .small-up\:grid__item--two-thirds {
    grid-column: span 8; }
    .small-up\:grid__item--eight-twelfths-first,
    .small-up\:grid__item--two-thirds-first {
      grid-column: 1 / span 8;
      grid-row-start: 1; }
  .small-up\:grid__item--nine-twelfths,
  .small-up\:grid__item--three-forths {
    grid-column: span 9; }
    .small-up\:grid__item--nine-twelfths-first,
    .small-up\:grid__item--three-forths-first {
      grid-column: 1 / span 9;
      grid-row-start: 1; }
  .small-up\:grid__item--ten-twelfths {
    grid-column: span 10; }
    .small-up\:grid__item--ten-twelfths-first {
      grid-column: 1 / span 10;
      grid-row-start: 1; }
  .small-up\:grid__item--eleven-twelfths {
    grid-column: span 11; }
    .small-up\:grid__item--eleven-twelfths-first {
      grid-column: 1 / span 11;
      grid-row-start: 1; }
  .small-up\:align--top {
    align-self: start; }
  .small-up\:align--center {
    align-self: center; }
  .small-up\:align--bottom {
    align-self: end; }
  .small-up\:justify--left {
    justify-self: start; }
  .small-up\:justify--center {
    justify-self: center; }
  .small-up\:justify--right {
    justify-self: end; } }

/*------------------------------
MEDIUM DEVICES AND UP
------------------------------*/
@media screen and (min-width: 768px) {
  .medium-up\:grid__item--small {
    grid-column: span 2; }
    .medium-up\:grid__item--small-first {
      grid-column: 1 / span 2;
      grid-row-start: 1; }
    .medium-up\:grid__item--small-centered {
      grid-column: 6 / span 2; }
  .medium-up\:grid__item--medium {
    grid-column: span 6; }
    .medium-up\:grid__item--medium-first {
      grid-column: 1 / span 2;
      grid-row-start: 1; }
    .medium-up\:grid__item--medium-centered {
      grid-column: 4 / span 6; }
  .medium-up\:grid__item--large {
    grid-column: span 8; }
    .medium-up\:grid__item--large-first {
      grid-column: 1 / span 8;
      grid-row-start: 1; }
    .medium-up\:grid__item--large-centered {
      grid-column: 3 / span 8; }
  .medium-up\:grid__item--full {
    grid-column: span 12; }
  .medium-up\:grid__item--one-twelfth {
    grid-column: span 1; }
    .medium-up\:grid__item--one-twelfth-first {
      grid-column: 1 / span 1;
      grid-row-start: 1; }
  .medium-up\:grid__item--two-twelfths {
    grid-column: span 2; }
    .medium-up\:grid__item--two-twelfths-first {
      grid-column: 1 / span 2;
      grid-row-start: 1; }
  .medium-up\:grid__item--three-twelfths,
  .medium-up\:grid__item--one-forth {
    grid-column: span 3; }
    .medium-up\:grid__item--three-twelfths-first,
    .medium-up\:grid__item--one-forth-first {
      grid-column: 1 / span 3;
      grid-row-start: 1; }
  .medium-up\:grid__item--four-twelfths,
  .medium-up\:grid__item--one-third {
    grid-column: span 4; }
    .medium-up\:grid__item--four-twelfths-first,
    .medium-up\:grid__item--one-third-first {
      grid-column: 1 / span 4;
      grid-row-start: 1; }
  .medium-up\:grid__item--five-twelfths {
    grid-column: span 5; }
    .medium-up\:grid__item--five-twelfths-first {
      grid-column: 1 / span 5;
      grid-row-start: 1; }
  .medium-up\:grid__item--six-twelfths,
  .medium-up\:grid__item--two-forths,
  .medium-up\:grid__item--one-half {
    grid-column: span 6; }
    .medium-up\:grid__item--six-twelfths-first,
    .medium-up\:grid__item--two-forths-first,
    .medium-up\:grid__item--one-half-first {
      grid-column: 1 / span 6;
      grid-row-start: 1; }
  .medium-up\:grid__item--seven-twelfths {
    grid-column: span 7; }
    .medium-up\:grid__item--seven-twelfths-first {
      grid-column: 1 / span 7;
      grid-row-start: 1; }
  .medium-up\:grid__item--eight-twelfths,
  .medium-up\:grid__item--two-thirds {
    grid-column: span 8; }
    .medium-up\:grid__item--eight-twelfths-first,
    .medium-up\:grid__item--two-thirds-first {
      grid-column: 1 / span 8;
      grid-row-start: 1; }
  .medium-up\:grid__item--nine-twelfths,
  .medium-up\:grid__item--three-forths {
    grid-column: span 9; }
    .medium-up\:grid__item--nine-twelfths-first,
    .medium-up\:grid__item--three-forths-first {
      grid-column: 1 / span 9;
      grid-row-start: 1; }
  .medium-up\:grid__item--ten-twelfths {
    grid-column: span 10; }
    .medium-up\:grid__item--ten-twelfths-first {
      grid-column: 1 / span 10;
      grid-row-start: 1; }
  .medium-up\:grid__item--eleven--twelfths {
    grid-column: span 11; }
    .medium-up\:grid__item--eleven--twelfths-first {
      grid-column: 1 / span 11;
      grid-row-start: 1; }
  .medium-up\:align--top {
    align-self: start; }
  .medium-up\:align--center {
    align-self: center; }
  .medium-up\:align--bottom {
    align-self: end; }
  .medium-up\:justify--left {
    justify-self: start; }
  .medium-up\:justify--center {
    justify-self: center; }
  .medium-up\:justify--right {
    justify-self: end; } }

/*------------------------------
LARGE DEVICES AND UP
------------------------------*/
@media screen and (min-width: 1024px) {
  .large-up\:grid__item--small {
    grid-column: span 2; }
    .large-up\:grid__item--small-first {
      grid-column: 1 / span 2;
      grid-row-start: 1; }
    .large-up\:grid__item--small-centered {
      grid-column: 6 / span 2; }
  .large-up\:grid__item--medium {
    grid-column: span 6; }
    .large-up\:grid__item--medium-first {
      grid-column: 1 / span 6;
      grid-row-start: 1; }
    .large-up\:grid__item--medium-centered {
      grid-column: 4 / span 6; }
  .large-up\:grid__item--large {
    grid-column: span 8; }
    .large-up\:grid__item--large-first {
      grid-column: 1 / span 8;
      grid-row-start: 1; }
    .large-up\:grid__item--large-centered {
      grid-column: 3 / span 8; }
  .large-up\:grid__item--full {
    grid-column: span 12; }
  .large-up\:grid__item--one-twelfth {
    grid-column: span 1; }
    .large-up\:grid__item--one-twelfth-first {
      grid-column: 1 / span 1;
      grid-row-start: 1; }
  .large-up\:grid__item--two-twelfths {
    grid-column: span 2; }
    .large-up\:grid__item--two-twelfths-first {
      grid-column: 1 / span 2;
      grid-row-start: 1; }
  .large-up\:grid__item--three-twelfths,
  .large-up\:grid__item--one-forth {
    grid-column: span 3; }
    .large-up\:grid__item--three-twelfths-first,
    .large-up\:grid__item--one-forth-first {
      grid-column: 1 / span 3;
      grid-row-start: 1; }
  .large-up\:grid__item--four-twelfths,
  .large-up\:grid__item--one-third {
    grid-column: span 4; }
    .large-up\:grid__item--four-twelfths-first,
    .large-up\:grid__item--one-third-first {
      grid-column: 1 / span 4;
      grid-row-start: 1; }
  .large-up\:grid__item--five-twelfths {
    grid-column: span 5; }
    .large-up\:grid__item--five-twelfths-first {
      grid-column: 1 / span 5;
      grid-row-start: 1; }
  .large-up\:grid__item--six-twelfths,
  .large-up\:grid__item--two-forths,
  .large-up\:grid__item--one-half {
    grid-column: span 6; }
    .large-up\:grid__item--six-twelfths-first,
    .large-up\:grid__item--two-forths-first,
    .large-up\:grid__item--one-half-first {
      grid-column: 1 / span 6;
      grid-row-start: 1; }
  .large-up\:grid__item--seven-twelfths {
    grid-column: span 7; }
    .large-up\:grid__item--seven-twelfths-first {
      grid-column: 1 / span 7;
      grid-row-start: 1; }
  .large-up\:grid__item--eight-twelfths,
  .large-up\:grid__item--two-thirds {
    grid-column: span 8; }
    .large-up\:grid__item--eight-twelfths-first,
    .large-up\:grid__item--two-thirds-first {
      grid-column: 1 / span 8;
      grid-row-start: 1; }
  .large-up\:grid__item--nine-twelfths,
  .large-up\:grid__item--three-forths {
    grid-column: span 9; }
    .large-up\:grid__item--nine-twelfths-first,
    .large-up\:grid__item--three-forths-first {
      grid-column: 1 / span 9;
      grid-row-start: 1; }
  .large-up\:grid__item--ten-twelfths {
    grid-column: span 10; }
    .large-up\:grid__item--ten-twelfths-first {
      grid-column: 1 / span 10;
      grid-row-start: 1; }
  .large-up\:grid__item--eleven--twelfths {
    grid-column: span 11; }
    .large-up\:grid__item--eleven--twelfths-first {
      grid-column: 1 / span 11;
      grid-row-start: 1; }
  .large-up\:align--top {
    align-self: start; }
  .large-up\:align--center {
    align-self: center; }
  .large-up\:align--bottom {
    align-self: end; }
  .large-up\:justify--left {
    justify-self: start; }
  .large-up\:justify--center {
    justify-self: center; }
  .large-up\:justify--right {
    justify-self: end; } }

/*------------------------------
EXTRA LARGE DEVICES AND UP
------------------------------*/
@media screen and (min-width: 1280px) {
  .extra-large-up\:grid__item--small {
    grid-column: span 2; }
    .extra-large-up\:grid__item--small-first {
      grid-column: 1 / span 2;
      grid-row-start: 1; }
    .extra-large-up\:grid__item--small-centered {
      grid-column: 6 / span 2; }
  .extra-large-up\:grid__item--medium {
    grid-column: span 6; }
    .extra-large-up\:grid__item--medium-first {
      grid-column: 1 / span 6;
      grid-row-start: 1; }
    .extra-large-up\:grid__item--medium-centered {
      grid-column: 4 / span 6; }
  .extra-large-up\:grid__item--large {
    grid-column: span 8; }
    .extra-large-up\:grid__item--large-first {
      grid-column: 1 / span 8;
      grid-row-start: 1; }
    .extra-large-up\:grid__item--large-centered {
      grid-column: 3 / span 8; }
  .extra-large-up\:grid__item--full {
    grid-column: span 12; }
  .extra-large-up\:grid__item--one-twelfth {
    grid-column: span 1; }
    .extra-large-up\:grid__item--one-twelfth-first {
      grid-column: 1 / span 1;
      grid-row-start: 1; }
  .extra-large-up\:grid__item--two-twelfths {
    grid-column: span 2; }
    .extra-large-up\:grid__item--two-twelfths-first {
      grid-column: 1 / span 2;
      grid-row-start: 1; }
  .extra-large-up\:grid__item--three-twelfths,
  .extra-large-up\:grid__item--one-forth {
    grid-column: span 3; }
    .extra-large-up\:grid__item--three-twelfths-first,
    .extra-large-up\:grid__item--one-forth-first {
      grid-column: 1 / span 3;
      grid-row-start: 1; }
  .extra-large-up\:grid__item--four-twelfths,
  .extra-large-up\:grid__item--one-third {
    grid-column: span 4; }
    .extra-large-up\:grid__item--four-twelfths-first,
    .extra-large-up\:grid__item--one-third-first {
      grid-column: 1 / span 4;
      grid-row-start: 1; }
  .extra-large-up\:grid__item--five-twelfths {
    grid-column: span 5; }
    .extra-large-up\:grid__item--five-twelfths-first {
      grid-column: 1 / span 5;
      grid-row-start: 1; }
  .extra-large-up\:grid__item--six-twelfths,
  .extra-large-up\:grid__item--two-forths,
  .extra-large-up\:grid__item--one-half {
    grid-column: span 6; }
    .extra-large-up\:grid__item--six-twelfths-first,
    .extra-large-up\:grid__item--two-forths-first,
    .extra-large-up\:grid__item--one-half-first {
      grid-column: 1 / span 6;
      grid-row-start: 1; }
  .extra-large-up\:grid__item--seven-twelfths {
    grid-column: span 7; }
    .extra-large-up\:grid__item--seven-twelfths-first {
      grid-column: 1 / span 7;
      grid-row-start: 1; }
  .extra-large-up\:grid__item--eight-twelfths,
  .extra-large-up\:grid__item--two-thirds {
    grid-column: span 8; }
    .extra-large-up\:grid__item--eight-twelfths-first,
    .extra-large-up\:grid__item--two-thirds-first {
      grid-column: 1 / span 8;
      grid-row-start: 1; }
  .extra-large-up\:grid__item--nine-twelfths,
  .extra-large-up\:grid__item--three-forths {
    grid-column: span 9; }
    .extra-large-up\:grid__item--nine-twelfths-first,
    .extra-large-up\:grid__item--three-forths-first {
      grid-column: 1 / span 9;
      grid-row-start: 1; }
  .extra-large-up\:grid__item--ten-twelfths {
    grid-column: span 10; }
    .extra-large-up\:grid__item--ten-twelfths-first {
      grid-column: 1 / span 10;
      grid-row-start: 1; }
  .extra-large-up\:grid__item--eleven--twelfths {
    grid-column: span 11; }
    .extra-large-up\:grid__item--eleven--twelfths-first {
      grid-column: 1 / span 11;
      grid-row-start: 1; }
  .extra-large-up\:align--top {
    align-self: start; }
  .extra-large-up\:align--center {
    align-self: center; }
  .extra-large-up\:align--bottom {
    align-self: end; }
  .extra-large-up\:justify--left {
    justify-self: start; }
  .extra-large-up\:justify--center {
    justify-self: center; }
  .extra-large-up\:justify--right {
    justify-self: end; } }

@font-face {
  font-family: 'DIN';
  font-weight: 600;
  src: url("/assets/fonts/DINWebPro-Medium.woff") format("woff"); }

@font-face {
  font-family: 'DIN';
  font-weight: 400;
  src: url("/assets/fonts/DINWebPro-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Publico';
  font-weight: 600;
  src: url("/assets/fonts/PublicoText-Semibold.woff") format("woff"); }

/*------------------------------
DEFAULT SETUP
------------------------------*/
html, input, textarea, button, select {
  font-size: 18px;
  font-family: 'DIN';
  color: #1C2427; }

.heading, .main-nav__item a, .brt__input label {
  font-weight: 600; }

.serif {
  font-family: 'Publico'; }

/*------------------------------
SET BOTTOM MARGIN
------------------------------*/
h1 {
  margin-bottom: 0.25em; }

h2 {
  margin-bottom: 0.35em; }

h3 {
  margin-bottom: 0.45em; }

h4 {
  margin-bottom: 0.55em; }

h5 {
  margin-bottom: 0.65em; }

h6, p {
  margin-bottom: 0.8em; }

/*------------------------------
ALIGNMENT
------------------------------*/
.text--left {
  text-align: left; }

.text--center {
  text-align: center; }

.text--right {
  text-align: right; }

@media screen and (min-width: 500px) {
  .small-up\:text--left {
    text-align: left; }
  .small-up\:text--center {
    text-align: center; }
  .small-up\:text--right {
    text-align: right; } }

@media screen and (min-width: 768px) {
  .medium-up\:text--left {
    text-align: left; }
  .medium-up\:text--center {
    text-align: center; }
  .medium-up\:text--right {
    text-align: right; } }

@media screen and (min-width: 1024px) {
  .large-up\:text--left {
    text-align: left; }
  .large-up\:text--center {
    text-align: center; }
  .large-up\:text--right {
    text-align: right; } }

@media screen and (min-width: 1280px) {
  .extra-large-up\:text--left {
    text-align: left; }
  .extra-large-up\:text--center {
    text-align: center; }
  .extra-large-up\:text--right {
    text-align: right; } }

/*------------------------------
SET SIZING
------------------------------*/
.text--extra-small {
  font-size: 0.66667rem;
  line-height: 1.7; }

.text--small, #close-property-filter, .error-message {
  font-size: 0.77778rem;
  line-height: 1.7; }

p, .text--base, .property-filter .form__select select {
  font-size: 1rem;
  line-height: 1.7; }

.text--medium, .button--large {
  font-size: 1.33333rem;
  line-height: 1.2; }
  .text--medium.heading, .heading.button--large, .main-nav__item a.button--large, .brt__input label.button--large, .main-nav__item a.text--medium, .brt__input label.text--medium {
    line-height: 1.3; }

.text--large {
  font-size: 1.88889rem;
  line-height: 1.2; }
  .text--large.heading, .main-nav__item a.text--large, .brt__input label.text--large {
    line-height: 1.3; }

.text--extra-large {
  font-size: 2.77778rem;
  line-height: 1.1; }
  .text--extra-large.heading, .main-nav__item a.text--extra-large, .brt__input label.text--extra-large {
    line-height: 1.25; }

.text--display-two {
  font-size: 3.33333rem;
  line-height: 1.1; }
  .text--display-two.heading, .main-nav__item a.text--display-two, .brt__input label.text--display-two {
    line-height: 1.25; }

.text--display-one {
  font-size: 4.22222rem;
  line-height: 1.1; }
  .text--display-one.heading, .main-nav__item a.text--display-one, .brt__input label.text--display-one {
    line-height: 1.25; }

@media screen and (min-width: 500px) {
  .small-up\:text--extra-small {
    font-size: 0.66667rem;
    line-height: 1.7; }
  .small-up\:text--small {
    font-size: 0.77778rem;
    line-height: 1.7; }
  .small-up\:text--base {
    font-size: 1rem;
    line-height: 1.7; }
  .small-up\:text--medium {
    font-size: 1.33333rem;
    line-height: 1.2; }
    .small-up\:text--medium.heading, .main-nav__item a.small-up\:text--medium, .brt__input label.small-up\:text--medium {
      line-height: 1.3; }
  .small-up\:text--large {
    font-size: 1.88889rem;
    line-height: 1.2; }
    .small-up\:text--large.heading, .main-nav__item a.small-up\:text--large, .brt__input label.small-up\:text--large {
      line-height: 1.3; }
  .small-up\:text--extra-large {
    font-size: 2.77778rem;
    line-height: 1.1; }
    .small-up\:text--extra-large.heading, .main-nav__item a.small-up\:text--extra-large, .brt__input label.small-up\:text--extra-large {
      line-height: 1.25; }
  .small-up\:text--display-two {
    font-size: 3.33333rem;
    line-height: 1.1; }
    .small-up\:text--display-two.heading, .main-nav__item a.small-up\:text--display-two, .brt__input label.small-up\:text--display-two {
      line-height: 1.25; }
  .small-up\:text--display-one {
    font-size: 4.22222rem;
    line-height: 1.1; }
    .small-up\:text--display-one.heading, .main-nav__item a.small-up\:text--display-one, .brt__input label.small-up\:text--display-one {
      line-height: 1.25; } }

@media screen and (min-width: 768px) {
  .medium-up\:text--extra-small {
    font-size: 0.66667rem;
    line-height: 1.7; }
  .medium-up\:text--small {
    font-size: 0.77778rem;
    line-height: 1.7; }
  p, .medium-up\:text--base {
    font-size: 1rem;
    line-height: 1.7; }
  .medium-up\:text--medium {
    font-size: 1.33333rem;
    line-height: 1.2; }
    .medium-up\:text--medium.heading, .main-nav__item a.medium-up\:text--medium, .brt__input label.medium-up\:text--medium {
      line-height: 1.3; }
  .medium-up\:text--large {
    font-size: 1.88889rem;
    line-height: 1.2; }
    .medium-up\:text--large.heading, .main-nav__item a.medium-up\:text--large, .brt__input label.medium-up\:text--large {
      line-height: 1.3; }
  .medium-up\:text--extra-large {
    font-size: 2.77778rem;
    line-height: 1.1; }
    .medium-up\:text--extra-large.heading, .main-nav__item a.medium-up\:text--extra-large, .brt__input label.medium-up\:text--extra-large {
      line-height: 1.25; }
  .medium-up\:text--display-two {
    font-size: 3.33333rem;
    line-height: 1.1; }
    .medium-up\:text--display-two.heading, .main-nav__item a.medium-up\:text--display-two, .brt__input label.medium-up\:text--display-two {
      line-height: 1.25; }
  .medium-up\:text--display-one {
    font-size: 4.22222rem;
    line-height: 1.1; }
    .medium-up\:text--display-one.heading, .main-nav__item a.medium-up\:text--display-one, .brt__input label.medium-up\:text--display-one {
      line-height: 1.25; } }

@media screen and (min-width: 1024px) {
  .large-up\:text--extra-small {
    font-size: 0.66667rem;
    line-height: 1.7; }
  .large-up\:text--small {
    font-size: 0.77778rem;
    line-height: 1.7; }
  p, .large-up\:text--base {
    font-size: 1rem;
    line-height: 1.7; }
  .large-up\:text--medium {
    font-size: 1.33333rem;
    line-height: 1.2; }
    .large-up\:text--medium.heading, .main-nav__item a.large-up\:text--medium, .brt__input label.large-up\:text--medium {
      line-height: 1.3; }
  .large-up\:text--large {
    font-size: 1.88889rem;
    line-height: 1.2; }
    .large-up\:text--large.heading, .main-nav__item a.large-up\:text--large, .brt__input label.large-up\:text--large {
      line-height: 1.3; }
  .large-up\:text--extra-large {
    font-size: 2.77778rem;
    line-height: 1.1; }
    .large-up\:text--extra-large.heading, .main-nav__item a.large-up\:text--extra-large, .brt__input label.large-up\:text--extra-large {
      line-height: 1.25; }
  .large-up\:text--display-two {
    font-size: 3.33333rem;
    line-height: 1.1; }
    .large-up\:text--display-two.heading, .main-nav__item a.large-up\:text--display-two, .brt__input label.large-up\:text--display-two {
      line-height: 1.25; }
  .large-up\:text--display-one {
    font-size: 4.22222rem;
    line-height: 1.1; }
    .large-up\:text--display-one.heading, .main-nav__item a.large-up\:text--display-one, .brt__input label.large-up\:text--display-one {
      line-height: 1.25; } }

@media screen and (min-width: 1280px) {
  .extra-large-up\:text--extra-small {
    font-size: 0.66667rem;
    line-height: 1.7; }
  .extra-large-up\:text--small {
    font-size: 0.77778rem;
    line-height: 1.7; }
  p, .extra-large-up\:text--base {
    font-size: 1rem;
    line-height: 1.7; }
  .extra-large-up\:text--medium {
    font-size: 1.33333rem;
    line-height: 1.2; }
    .extra-large-up\:text--medium.heading, .main-nav__item a.extra-large-up\:text--medium, .brt__input label.extra-large-up\:text--medium {
      line-height: 1.3; }
  .extra-large-up\:text--large {
    font-size: 1.88889rem;
    line-height: 1.2; }
    .extra-large-up\:text--large.heading, .main-nav__item a.extra-large-up\:text--large, .brt__input label.extra-large-up\:text--large {
      line-height: 1.3; }
  .extra-large-up\:text--extra-large {
    font-size: 2.77778rem;
    line-height: 1.1; }
    .extra-large-up\:text--extra-large.heading, .main-nav__item a.extra-large-up\:text--extra-large, .brt__input label.extra-large-up\:text--extra-large {
      line-height: 1.25; }
  .extra-large-up\:text--display-two {
    font-size: 3.33333rem;
    line-height: 1.1; }
    .extra-large-up\:text--display-two.heading, .main-nav__item a.extra-large-up\:text--display-two, .brt__input label.extra-large-up\:text--display-two {
      line-height: 1.25; }
  .extra-large-up\:text--display-one {
    font-size: 4.22222rem;
    line-height: 1.1; }
    .extra-large-up\:text--display-one.heading, .main-nav__item a.extra-large-up\:text--display-one, .brt__input label.extra-large-up\:text--display-one {
      line-height: 1.25; } }

.push--flush {
  margin: 0; }

.push--top-flush {
  margin-top: 0; }

.push--bottom-flush {
  margin-bottom: 0; }

.push--right-flush {
  margin-right: 0; }

.push--left-flush {
  margin-left: 0; }

.push--vertical-flush {
  margin-top: 0;
  margin-bottom: 0; }

.push--horizontal-flush {
  margin-left: 0;
  margin-right: 0; }

.push--small {
  margin: 4px; }

.push--top-small {
  margin-top: 4px; }

.push--bottom-small {
  margin-bottom: 4px; }

.push--right-small {
  margin-right: 4px; }

.push--left-small {
  margin-left: 4px; }

.push--vertical-small {
  margin-top: 4px;
  margin-bottom: 4px; }

.push--horizontal-small {
  margin-left: 4px;
  margin-right: 4px; }

.push--medium {
  margin: 10px; }

.push--top-medium {
  margin-top: 10px; }

.push--bottom-medium {
  margin-bottom: 10px; }

.push--right-medium {
  margin-right: 10px; }

.push--left-medium {
  margin-left: 10px; }

.push--vertical-medium {
  margin-top: 10px;
  margin-bottom: 10px; }

.push--horizontal-medium {
  margin-left: 10px;
  margin-right: 10px; }

.push--large {
  margin: 20px; }

.push--top-large {
  margin-top: 20px; }

.push--bottom-large {
  margin-bottom: 20px; }

.push--right-large {
  margin-right: 20px; }

.push--left-large {
  margin-left: 20px; }

.push--vertical-large {
  margin-top: 20px;
  margin-bottom: 20px; }

.push--horizontal-large {
  margin-left: 20px;
  margin-right: 20px; }

.push--extra-large {
  margin: 40px; }

.push--top-extra-large {
  margin-top: 40px; }

.push--bottom-extra-large {
  margin-bottom: 40px; }

.push--right-extra-large {
  margin-right: 40px; }

.push--left-extra-large {
  margin-left: 40px; }

.push--vertical-extra-large {
  margin-top: 40px;
  margin-bottom: 40px; }

.push--horizontal-extra-large {
  margin-left: 40px;
  margin-right: 40px; }

@media screen and (min-width: 500px) {
  .small-up\:push--flush {
    margin: 0; }
  .small-up\:push--top-flush {
    margin-top: 0; }
  .small-up\:push--bottom-flush {
    margin-bottom: 0; }
  .small-up\:push--right-flush {
    margin-right: 0; }
  .small-up\:push--left-flush {
    margin-left: 0; }
  .small-up\:push--vertical-flush {
    margin-top: 0;
    margin-bottom: 0; }
  .small-up\:push--horizontal-flush {
    margin-left: 0;
    margin-right: 0; }
  .small-up\:push--small {
    margin: 4px; }
  .small-up\:push--top-small {
    margin-top: 4px; }
  .small-up\:push--bottom-small {
    margin-bottom: 4px; }
  .small-up\:push--right-small {
    margin-right: 4px; }
  .small-up\:push--left-small {
    margin-left: 4px; }
  .small-up\:push--vertical-small {
    margin-top: 4px;
    margin-bottom: 4px; }
  .small-up\:push--horizontal-small {
    margin-left: 4px;
    margin-right: 4px; }
  .small-up\:push--medium {
    margin: 10px; }
  .small-up\:push--top-medium {
    margin-top: 10px; }
  .small-up\:push--bottom-medium {
    margin-bottom: 10px; }
  .small-up\:push--right-medium {
    margin-right: 10px; }
  .small-up\:push--left-medium {
    margin-left: 10px; }
  .small-up\:push--vertical-medium {
    margin-top: 10px;
    margin-bottom: 10px; }
  .small-up\:push--horizontal-medium {
    margin-left: 10px;
    margin-right: 10px; }
  .small-up\:push--large {
    margin: 20px; }
  .small-up\:push--top-large {
    margin-top: 20px; }
  .small-up\:push--bottom-large {
    margin-bottom: 20px; }
  .small-up\:push--right-large {
    margin-right: 20px; }
  .small-up\:push--left-large {
    margin-left: 20px; }
  .small-up\:push--vertical-large {
    margin-top: 20px;
    margin-bottom: 20px; }
  .small-up\:push--horizontal-large {
    margin-left: 20px;
    margin-right: 20px; }
  .small-up\:push--extra-large {
    margin: 40px; }
  .small-up\:push--top-extra-large {
    margin-top: 40px; }
  .small-up\:push--bottom-extra-large {
    margin-bottom: 40px; }
  .small-up\:push--right-extra-large {
    margin-right: 40px; }
  .small-up\:push--left-extra-large {
    margin-left: 40px; }
  .small-up\:push--vertical-extra-large {
    margin-top: 40px;
    margin-bottom: 40px; }
  .small-up\:push--horizontal-extra-large {
    margin-left: 40px;
    margin-right: 40px; } }

@media screen and (min-width: 768px) {
  .medium-up\:push--flush {
    margin: 0; }
  .medium-up\:push--top-flush {
    margin-top: 0; }
  .medium-up\:push--bottom-flush {
    margin-bottom: 0; }
  .medium-up\:push--right-flush {
    margin-right: 0; }
  .medium-up\:push--left-flush {
    margin-left: 0; }
  .medium-up\:push--vertical-flush {
    margin-top: 0;
    margin-bottom: 0; }
  .medium-up\:push--horizontal-flush {
    margin-left: 0;
    margin-right: 0; }
  .medium-up\:push--small {
    margin: 4px; }
  .medium-up\:push--top-small {
    margin-top: 4px; }
  .medium-up\:push--bottom-small {
    margin-bottom: 4px; }
  .medium-up\:push--right-small {
    margin-right: 4px; }
  .medium-up\:push--left-small {
    margin-left: 4px; }
  .medium-up\:push--vertical-small {
    margin-top: 4px;
    margin-bottom: 4px; }
  .medium-up\:push--horizontal-small {
    margin-left: 4px;
    margin-right: 4px; }
  .medium-up\:push--medium {
    margin: 10px; }
  .medium-up\:push--top-medium {
    margin-top: 10px; }
  .medium-up\:push--bottom-medium {
    margin-bottom: 10px; }
  .medium-up\:push--right-medium {
    margin-right: 10px; }
  .medium-up\:push--left-medium {
    margin-left: 10px; }
  .medium-up\:push--vertical-medium {
    margin-top: 10px;
    margin-bottom: 10px; }
  .medium-up\:push--horizontal-medium {
    margin-left: 10px;
    margin-right: 10px; }
  .medium-up\:push--large {
    margin: 20px; }
  .medium-up\:push--top-large {
    margin-top: 20px; }
  .medium-up\:push--bottom-large {
    margin-bottom: 20px; }
  .medium-up\:push--right-large {
    margin-right: 20px; }
  .medium-up\:push--left-large {
    margin-left: 20px; }
  .medium-up\:push--vertical-large {
    margin-top: 20px;
    margin-bottom: 20px; }
  .medium-up\:push--horizontal-large {
    margin-left: 20px;
    margin-right: 20px; }
  .medium-up\:push--extra-large {
    margin: 40px; }
  .medium-up\:push--top-extra-large {
    margin-top: 40px; }
  .medium-up\:push--bottom-extra-large {
    margin-bottom: 40px; }
  .medium-up\:push--right-extra-large {
    margin-right: 40px; }
  .medium-up\:push--left-extra-large {
    margin-left: 40px; }
  .medium-up\:push--vertical-extra-large {
    margin-top: 40px;
    margin-bottom: 40px; }
  .medium-up\:push--horizontal-extra-large {
    margin-left: 40px;
    margin-right: 40px; } }

@media screen and (min-width: 1024px) {
  .large-up\:push--flush {
    margin: 0; }
  .large-up\:push--top-flush {
    margin-top: 0; }
  .large-up\:push--bottom-flush {
    margin-bottom: 0; }
  .large-up\:push--right-flush {
    margin-right: 0; }
  .large-up\:push--left-flush {
    margin-left: 0; }
  .large-up\:push--vertical-flush {
    margin-top: 0;
    margin-bottom: 0; }
  .large-up\:push--horizontal-flush {
    margin-left: 0;
    margin-right: 0; }
  .large-up\:push--small {
    margin: 4px; }
  .large-up\:push--top-small {
    margin-top: 4px; }
  .large-up\:push--bottom-small {
    margin-bottom: 4px; }
  .large-up\:push--right-small {
    margin-right: 4px; }
  .large-up\:push--left-small {
    margin-left: 4px; }
  .large-up\:push--vertical-small {
    margin-top: 4px;
    margin-bottom: 4px; }
  .large-up\:push--horizontal-small {
    margin-left: 4px;
    margin-right: 4px; }
  .large-up\:push--medium {
    margin: 10px; }
  .large-up\:push--top-medium {
    margin-top: 10px; }
  .large-up\:push--bottom-medium {
    margin-bottom: 10px; }
  .large-up\:push--right-medium {
    margin-right: 10px; }
  .large-up\:push--left-medium {
    margin-left: 10px; }
  .large-up\:push--vertical-medium {
    margin-top: 10px;
    margin-bottom: 10px; }
  .large-up\:push--horizontal-medium {
    margin-left: 10px;
    margin-right: 10px; }
  .large-up\:push--large {
    margin: 20px; }
  .large-up\:push--top-large {
    margin-top: 20px; }
  .large-up\:push--bottom-large {
    margin-bottom: 20px; }
  .large-up\:push--right-large {
    margin-right: 20px; }
  .large-up\:push--left-large {
    margin-left: 20px; }
  .large-up\:push--vertical-large {
    margin-top: 20px;
    margin-bottom: 20px; }
  .large-up\:push--horizontal-large {
    margin-left: 20px;
    margin-right: 20px; }
  .large-up\:push--extra-large {
    margin: 40px; }
  .large-up\:push--top-extra-large {
    margin-top: 40px; }
  .large-up\:push--bottom-extra-large {
    margin-bottom: 40px; }
  .large-up\:push--right-extra-large {
    margin-right: 40px; }
  .large-up\:push--left-extra-large {
    margin-left: 40px; }
  .large-up\:push--vertical-extra-large {
    margin-top: 40px;
    margin-bottom: 40px; }
  .large-up\:push--horizontal-extra-large {
    margin-left: 40px;
    margin-right: 40px; } }

@media screen and (min-width: 1280px) {
  .extra-large-up\:push--flush {
    margin: 0; }
  .extra-large-up\:push--top-flush {
    margin-top: 0; }
  .extra-large-up\:push--bottom-flush {
    margin-bottom: 0; }
  .extra-large-up\:push--right-flush {
    margin-right: 0; }
  .extra-large-up\:push--left-flush {
    margin-left: 0; }
  .extra-large-up\:push--vertical-flush {
    margin-top: 0;
    margin-bottom: 0; }
  .extra-large-up\:push--horizontal-flush {
    margin-left: 0;
    margin-right: 0; }
  .extra-large-up\:push--small {
    margin: 4px; }
  .extra-large-up\:push--top-small {
    margin-top: 4px; }
  .extra-large-up\:push--bottom-small {
    margin-bottom: 4px; }
  .extra-large-up\:push--right-small {
    margin-right: 4px; }
  .extra-large-up\:push--left-small {
    margin-left: 4px; }
  .extra-large-up\:push--vertical-small {
    margin-top: 4px;
    margin-bottom: 4px; }
  .extra-large-up\:push--horizontal-small {
    margin-left: 4px;
    margin-right: 4px; }
  .extra-large-up\:push--medium {
    margin: 10px; }
  .extra-large-up\:push--top-medium {
    margin-top: 10px; }
  .extra-large-up\:push--bottom-medium {
    margin-bottom: 10px; }
  .extra-large-up\:push--right-medium {
    margin-right: 10px; }
  .extra-large-up\:push--left-medium {
    margin-left: 10px; }
  .extra-large-up\:push--vertical-medium {
    margin-top: 10px;
    margin-bottom: 10px; }
  .extra-large-up\:push--horizontal-medium {
    margin-left: 10px;
    margin-right: 10px; }
  .extra-large-up\:push--large {
    margin: 20px; }
  .extra-large-up\:push--top-large {
    margin-top: 20px; }
  .extra-large-up\:push--bottom-large {
    margin-bottom: 20px; }
  .extra-large-up\:push--right-large {
    margin-right: 20px; }
  .extra-large-up\:push--left-large {
    margin-left: 20px; }
  .extra-large-up\:push--vertical-large {
    margin-top: 20px;
    margin-bottom: 20px; }
  .extra-large-up\:push--horizontal-large {
    margin-left: 20px;
    margin-right: 20px; }
  .extra-large-up\:push--extra-large {
    margin: 40px; }
  .extra-large-up\:push--top-extra-large {
    margin-top: 40px; }
  .extra-large-up\:push--bottom-extra-large {
    margin-bottom: 40px; }
  .extra-large-up\:push--right-extra-large {
    margin-right: 40px; }
  .extra-large-up\:push--left-extra-large {
    margin-left: 40px; }
  .extra-large-up\:push--vertical-extra-large {
    margin-top: 40px;
    margin-bottom: 40px; }
  .extra-large-up\:push--horizontal-extra-large {
    margin-left: 40px;
    margin-right: 40px; } }

button:focus,
button:active,
select:focus,
select:active {
  outline: none;
  border: none; }

.button, .button--primary, .button--primary-outline, .button--secondary, .button--secondary-outline {
  display: inline-block;
  min-width: 140px;
  height: 45px;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 45px;
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  transition: all 0.2s ease-in-out; }
  .button--large {
    min-width: 250px;
    height: 60px;
    line-height: 60px; }

.button--primary {
  color: #fff;
  background-color: #A53860;
  border: 2px solid #A53860; }
  .button--primary:hover {
    background-color: #771338;
    border-color: #771338; }
  .button--primary-outline {
    background-color: transparent;
    border: 2px solid #A53860;
    color: #A53860; }
    .button--primary-outline:hover {
      background-color: #A53860;
      color: #fff; }

.button--secondary {
  color: #031A1A;
  background-color: #49A5A5;
  border: 2px solid #49A5A5; }
  .button--secondary:hover {
    background-color: #6EBABA;
    border-color: #6EBABA; }
  .button--secondary-outline {
    background-color: transparent;
    border: 2px solid #49A5A5; }
    .button--secondary-outline:hover {
      background-color: #49A5A5; }

.button--tertiary {
  font-size: 0.77778rem;
  color: #57737A;
  text-align: left;
  display: block;
  padding: 0px 36px 0px 0;
  position: relative;
  transition: all 0.2s ease-in; }
  .button--tertiary:hover {
    color: #49A5A5; }
  .button--tertiary:after {
    display: block;
    content: url("/assets/icons/icon-arrow-right.svg");
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
    transition: all 0.2s ease-in; }
  .button--tertiary:hover:after {
    right: 0; }

.button-with-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0; }
  .button-with-icon svg {
    margin-right: 4px; }

/*------------------------------
GENERAL SETUP
------------------------------*/
label {
  display: block; }

input, select {
  height: 45px; }

textarea {
  height: 250px; }

input, textarea {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid #BED1D5; }
  input:focus, textarea:focus {
    border: 1px solid #49A5A5; }

.form__select {
  width: 100%;
  overflow-x: hidden;
  border: 1px solid #BED1D5;
  background: url("/assets/icons/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right; }

select {
  background-color: transparent;
  padding: 4px 8px;
  width: 100%;
  border: none;
  -moz-appearance: none;
  appearance: none; }
  select option {
    max-width: 50px; }

/*------------------------------
CHECKBOX INPUT
------------------------------*/
.form__checkbox {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent; }
  .form__checkbox input {
    opacity: 0;
    flex-grow: 0;
    width: 35px;
    height: 45px; }
  .form__checkbox .checkmark {
    width: 32px;
    height: 44px;
    padding: 12px 12px 12px 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .form__checkbox .checkmark:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-color: #ebebeb;
      transition: all 0.2s ease-in-out; }
  .form__checkbox input:checked ~ .checkmark:after {
    background-size: 20px;
    background-color: green;
    background-image: url("/check.svg"); }

/*------------------------------
RADIO INPUT
------------------------------*/
.form__radio {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent; }
  .form__radio input {
    width: 35px;
    height: 45px;
    flex-grow: 0;
    opacity: 0; }
  .form__radio .radio {
    display: block;
    width: 20px;
    height: 44px;
    padding: 12px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .form__radio .radio:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: #ebebeb;
      transition: all 0.2s ease-in-out; }
  .form__radio input:checked ~ .radio:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-color: #49A5A5; }
  .form__radio input:checked ~ .radio:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 100%;
    transition: all 0.2s ease-in-out; }

/*------------------------------
TOGGLE INPUT
------------------------------*/
.form__toggle {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: transparent; }
  .form__toggle input {
    opacity: 0;
    flex-grow: 0;
    width: 45px;
    height: 45px; }
  .form__toggle .track {
    display: block;
    width: 35px;
    height: 45px;
    padding: 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .form__toggle .track:after {
      content: '';
      display: block;
      width: 30px;
      height: 10px;
      margin-top: 7px;
      border-radius: 7px;
      border: 1px solid #BED1D5;
      background-color: #F6F8F9;
      transition: all 0.2s ease-in-out; }
    .form__toggle .track:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 18px;
      height: 18px;
      background-color: white;
      border: 1px solid #BED1D5;
      border-radius: 100%;
      transition: all 0.2s ease-in-out; }
  .form__toggle input:checked ~ .track:after {
    background-color: #C0E2E2; }
  .form__toggle input:checked ~ .track:before {
    transform: translateX(15px);
    background-color: #49A5A5;
    border: 1px solid #197777; }

.image-wrapper, .image-wrapper--one-by-one, .image-wrapper--four-by-three, .image-wrapper--three-by-four, .image-wrapper--sixteen-by-nine {
  position: relative;
  width: 100%;
  overflow: hidden; }

.image-wrapper--one-by-one {
  padding-top: 100%; }

.image-wrapper--four-by-three {
  padding-top: 75%; }

.image-wrapper--three-by-four {
  padding-top: 133%; }

.image-wrapper--sixteen-by-nine {
  padding-top: 56%; }

.image-wrapper--one-by-one img,
.image-wrapper--four-by-three img,
.image-wrapper--three-by-four img,
.image-wrapper--sixteen-by-nine img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%); }

.shadow, .property-type__card, .faqs__item-wrapper, .fixed-cta, .buying-step__card, .glide__slide img {
  box-shadow: 0px 0px 20px #BED1D5; }

.shadow-dark {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15); }

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  width: calc(100% + 20px); }
  @media screen and (min-width: 1024px) {
    .flex-grid {
      margin-right: -20px;
      margin-left: -20px;
      width: calc(100% + 40px); } }

.flex-item {
  padding: 10px; }
  .flex-item div {
    height: 100px;
    background-color: black; }
  @media screen and (min-width: 1024px) {
    .flex-item {
      padding: 20px; } }

.hide {
  display: none; }

.show {
  display: block; }

@media screen and (min-width: 500px) {
  .small-up\:hide {
    display: none; }
  .small-up\:show {
    display: block; } }

@media screen and (min-width: 768px) {
  .medium-up\:hide {
    display: none; }
  .medium-up\:show {
    display: block; } }

@media screen and (min-width: 1024px) {
  .large-up\:hide {
    display: none; }
  .large-up\:show {
    display: block; } }

@media screen and (min-width: 1280px) {
  .extra-large-up\:hide {
    display: none; }
  .extra-large-up\:show {
    display: block; } }

.navbar {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  position: relative; }

.navbar .logo {
  display: block;
  max-width: 160px; }

.main-nav {
  position: absolute;
  top: -100vh;
  left: 0;
  z-index: 10;
  transition: all 0.2s ease-in; }
  .main-nav.open {
    transform: translateY(100vh); }
  @media screen and (min-width: 1024px) {
    .main-nav {
      position: static; } }

.main-nav__list {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 1024px) {
    .main-nav__list {
      flex-direction: row;
      justify-content: flex-end;
      height: auto;
      width: auto; } }

.menu-open {
  margin-left: auto; }
  @media screen and (min-width: 1024px) {
    .menu-open {
      display: none; } }

.main-nav__item.menu-close {
  padding-top: 20px;
  position: absolute;
  top: 0;
  right: 0; }
  @media screen and (min-width: 1024px) {
    .main-nav__item.menu-close {
      display: none; } }

.main-nav__item {
  font-size: 1.33333rem; }
  @media screen and (min-width: 1024px) {
    .main-nav__item {
      margin-right: 40px;
      font-size: 1rem; } }
  .main-nav__item a {
    color: #57737A;
    text-decoration: none;
    display: block;
    padding: 12px 0; }

@media screen and (min-width: 1024px) {
  .main-nav__item:last-child {
    margin-right: 0; } }

.contact-details__item {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0; }

.contact-details__icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-top: 2px; }

.contact-details__text {
  margin-bottom: 0; }

.footer-nav__item a {
  color: #1C2427;
  text-decoration: none;
  display: block;
  padding: 10px 0;
  transition: all 0.2s ease-in; }
  .footer-nav__item a:hover {
    color: #57737A; }

.copyright {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid #F6F8F9; }
  .copyright a {
    color: #197777; }

.property-type__card {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 8px;
  transition: all 0.2s ease-in; }
  .property-type__card:hover {
    border: 2px solid #A53860; }
  .property-type__card:hover .button--tertiary {
    color: #49A5A5; }
  .property-type__card:hover .button--tertiary:after {
    right: 0; }
  @media screen and (min-width: 768px) {
    .property-type__card {
      flex-direction: column; } }

.property-type__illustration {
  width: 40%;
  padding-right: 10px; }
  @media screen and (min-width: 500px) {
    .property-type__illustration {
      padding-right: 20px; } }
  @media screen and (min-width: 768px) {
    .property-type__illustration {
      padding-right: 0;
      width: 100%; } }

.property-type__name {
  width: 60%;
  padding-left: 10px;
  color: #1C2427;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media screen and (min-width: 500px) {
    .property-type__name {
      padding-left: 20px;
      width: 180px; } }
  @media screen and (min-width: 768px) {
    .property-type__name {
      padding-left: 0;
      width: 100%; } }
  .property-type__name .button--tertiary {
    align-self: flex-start; }

@media screen and (min-width: 1024px) {
  .property-card .grid {
    grid-row-gap: 20px; } }

.property-card:nth-child(odd) {
  background-color: #F6F8F9; }
  @media screen and (min-width: 1024px) {
    .property-card:nth-child(odd) .property-card__image {
      grid-column: 7 / span 6;
      grid-row: 2 / span 6; } }
  @media screen and (min-width: 1024px) {
    .property-card:nth-child(odd) .property-card__image.no-title {
      grid-column: 7 / span 6;
      grid-row: 1 / span 6; } }

.property-card__title {
  text-transform: uppercase; }
  .property-card__title span {
    display: block;
    width: 100%; }

@media screen and (min-width: 1024px) {
  .property-card__headline-locality {
    grid-column: span 6; } }

.property-card__locality {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize; }
  @media screen and (min-width: 1024px) {
    .property-card__locality {
      justify-content: flex-start; } }
  .property-card__locality-icon, .property-card__locality-name {
    margin: 0 4px; }

.property-card__image .image-wrapper--four-by-three {
  border-radius: 4px;
  filter: drop-shadow(0px 0px 20px #F6F8F9); }

.property-card__image .property-card__label-wrapper {
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.property-card__image .property-card__label, .property-card__image .property-card__label--dark, .property-card__image .property-card__label--green {
  align-self: flex-start;
  padding: 8px;
  margin-bottom: 8px;
  font-size: 0.77778rem;
  color: #fff;
  background-color: #771338; }

.property-card__image .property-card__label--dark {
  background-color: #1C2427; }

.property-card__image .property-card__label--green {
  background-color: #197777; }

@media screen and (min-width: 1024px) {
  .property-card__image {
    grid-row: 2 / span 6; }
  .property-card__image.no-title {
    grid-row: 1 / span 6; } }

.property-card__price-row {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .property-card__price-row p {
    margin-bottom: 0; }

.property-card__features-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid #BED1D5; }

.property-card__feature {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .property-card__feature .property-card__feature-icon {
    width: 24px;
    height: 24px;
    margin-right: 4px; }
  .property-card__feature .property-card__feature-text {
    font-size: 0.77778rem;
    line-height: 24px; }
  .property-card__feature .property-card__feature-text-description {
    display: none; }
    @media screen and (min-width: 500px) {
      .property-card__feature .property-card__feature-text-description {
        display: initial; } }

.faqs__item-wrapper {
  padding: 20px 20px 24px 20px;
  border-radius: 4px; }
  .faqs__item-wrapper.open .faqs__item-icon {
    transform: rotate(180deg); }
  .faqs__item-wrapper.open .faqs__item-answer-wrapper {
    height: auto; }
  .faqs__item-wrapper.open .faqs__item-answer {
    opacity: 1;
    transform: translateY(0); }

.faqs__item {
  display: flex;
  justify-content: space-between; }
  .faqs__item p {
    margin-bottom: 0; }

.faqs__item-icon {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  transition: all 0.2s ease-in; }

.faqs__item-answer-wrapper {
  height: 0;
  overflow: hidden; }

.faqs__item-answer {
  transition: all 0.2s ease-in;
  transform-origin: top;
  transform: translateY(-100%);
  opacity: 0; }

.brt-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  border-radius: 23px;
  border: 1px solid #94ACB3;
  background-color: #F6F8F9; }

.brt__input {
  position: relative;
  width: 50%;
  height: 100%; }
  .brt__input input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    width: 100%;
    height: 100%; }
  .brt__input span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 23px;
    transform: translateX(100%) scale(1.1);
    background-color: #49A5A5;
    transition: all 0.2s cubic-bezier(0.35, -0.14, 0.31, 1.32); }
  .brt__input input:checked + span {
    color: #fff;
    transform: translateX(0) scale(1.1); }
  .brt__input label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 45px;
    line-height: 45px;
    width: 100%;
    text-align: center; }

.brt-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  border-radius: 23px;
  border: 1px solid #94ACB3;
  background-color: #F6F8F9; }

.brt__input {
  position: relative;
  width: 50%;
  height: 100%; }
  .brt__input input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    width: 100%;
    height: 100%; }
  .brt__input span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 23px;
    transform: translateX(100%) scale(1.1);
    background-color: #49A5A5;
    transition: all 0.2s cubic-bezier(0.35, -0.14, 0.31, 1.32); }
  .brt__input input:checked + span {
    color: #fff;
    transform: translateX(0) scale(1.1); }
  .brt__input label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 45px;
    line-height: 45px;
    width: 100%;
    text-align: center; }

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.pagination__item a,
.pagination__item button {
  display: block;
  width: 40px;
  height: 40px;
  margin: 4px;
  text-align: center;
  line-height: 40px;
  color: #57737A;
  text-decoration: none;
  border: 1px solid #BED1D5; }
  .pagination__item a.active,
  .pagination__item button.active {
    background-color: #49A5A5;
    color: #031A1A; }

.property-filter {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 100%;
  left: 0;
  z-index: 10;
  background-color: white;
  overflow: scroll;
  transition: all 0.2s ease-in-out; }
  .property-filter.open {
    transform: translateY(100%); }
  .property-filter .brt-wrapper {
    max-width: 300px;
    margin: 20px auto; }

@media screen and (min-width: 1024px) {
  .property-filter {
    position: static;
    overflow: initial;
    padding-top: 0;
    padding-bottom: 12px; }
    .property-filter.open {
      transform: translateY(0); }
    .property-filter .filter-properties__heading {
      display: none; }
  #toggle-property-filter {
    display: none; } }

#close-property-filter {
  color: #94ACB3; }

.buy-rent__radio-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.form__toggle-group {
  text-align: left;
  padding: 8px 0;
  width: 100%;
  border-bottom: 1px solid #BED1D5; }
  .form__toggle-group .button__wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .form__toggle-group .button__icon {
    transition: all 0.2s ease-in; }
  .form__toggle-group + .toggle__content {
    height: 0;
    overflow: hidden; }
  .form__toggle-group.open + .toggle__content {
    height: auto; }
  .form__toggle-group.open .button__icon {
    transform: rotate(180deg); }

.property-filter .form__select {
  border: none; }
  .property-filter .form__select select {
    padding: 0;
    border-bottom: 1px solid #BED1D5; }

.toggle__content .form__select select {
  padding-left: 12px; }

.toggle-tags__wrapper {
  padding-top: 0;
  padding-bottom: 0; }

.filter-tag {
  background-color: #C0E2E2;
  color: #197777;
  border-radius: 4px;
  margin-right: 12px;
  margin-top: 12px;
  padding: 4px 12px 4px 30px;
  position: relative; }
  .filter-tag:before {
    position: absolute;
    left: 8px;
    top: 8px;
    display: block;
    content: "";
    background: url("/assets/icons/close.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px; }

@media screen and (min-width: 1024px) {
  .large-up\:property-filter {
    display: flex;
    justify-content: flex-start;
    background-color: #F6F8F9;
    padding: 12px; }
    .large-up\:property-filter .form__toggle-group,
    .large-up\:property-filter .form__select select {
      border: none;
      padding: 0;
      height: 45px; }
      .large-up\:property-filter .form__toggle-group .button__icon,
      .large-up\:property-filter .form__select select .button__icon {
        height: 24px;
        margin-left: 10px; }
    .large-up\:property-filter .input__wrapper {
      border-right: 1px solid #BED1D5;
      padding: 0 12px;
      position: relative; }
      .large-up\:property-filter .input__wrapper select {
        padding-right: 30px;
        width: 100%; }
      .large-up\:property-filter .input__wrapper:last-of-type {
        border-right: none; }
      .large-up\:property-filter .input__wrapper .toggle__content {
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 10;
        min-width: 200px;
        background: white;
        border-radius: 4px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15); }
    .large-up\:property-filter .toggle.open + .toggle__content {
      padding: 12px; }
    .large-up\:property-filter button {
      margin-left: auto; } }

.property-detail__ref {
  font-weight: 400;
  color: #57737A; }

.property-detail__price {
  color: #197777; }

.property-detail__features {
  display: flex;
  flex-wrap: wrap; }

.property-detail__feature {
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 12px; }

.property-detail__feature-icon {
  height: 24px;
  width: 24px;
  margin-right: 4px; }

.property-detail__feature-text {
  line-height: 24px; }

.property-detail__plans-link {
  color: #197777; }

@media screen and (min-width: 1024px) {
  .property-detail__link {
    display: inline-block; } }

.property-detail__button {
  display: inline-block; }

@media screen and (min-width: 1024px) {
  .property-detail__title {
    grid-column: 7 / span 6; }
  .property-detail__carousel {
    grid-column: 1 / span 6;
    grid-row: 1 / span 3;
    align-self: start; }
  .property-detail__features {
    grid-column: 7 / span 6;
    grid-row: 2; }
  .property-detail__description {
    grid-column: 1 / span 6; }
  .property-detail__highlights {
    grid-column: 7 / span 6;
    grid-row: 2;
    align-self: start;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    border-radius: 4px;
    border: 1px solid #BED1D5; }
  .property-detail__highlights h2 {
    width: 100%; } }

.fixed-cta {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  padding: 12px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .fixed-cta {
      display: none; } }
  .fixed-cta p {
    margin-bottom: 0; }

.timeline {
  position: relative; }

@media screen and (min-width: 1024px) {
  .timeline:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: #49A5A5; }
  .buying-step .grid__item:after {
    display: block;
    content: '';
    width: 35px;
    height: 2px;
    background-color: #49A5A5;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .buying-step:nth-child(even) .grid__item:after {
    right: -20px; }
  .buying-step:nth-child(odd) .grid__item:after {
    left: -20px; } }

.buying-process__bg {
  background-color: #F6F8F9; }

.buying-step {
  margin-bottom: 20px; }
  @media screen and (min-width: 1024px) {
    .buying-step {
      margin-bottom: 40px; } }

.buying-step__card {
  background-color: white;
  padding: 20px;
  position: relative; }
  @media screen and (min-width: 1024px) {
    .buying-step__card {
      display: flex;
      padding: 40px 20px; } }

.buying-step .grid__item {
  position: relative; }

.buying-step__count {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: #A53860;
  color: white;
  text-align: center;
  line-height: 24px; }

.buying-step__icon {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: 8px;
  margin-bottom: 12px; }

@media screen and (min-width: 1024px) {
  .buying-step:nth-child(even) .grid__item {
    grid-column: 1 / span 6;
    padding-right: 35px; }
  .buying-step:nth-child(odd) .grid__item {
    grid-column: 7 / span 6;
    padding-left: 35px; } }

button {
  cursor: pointer; }

.more-questions__person-name {
  margin-bottom: 0; }

.more-questions__job-title {
  color: #57737A; }

.glide__slide img {
  border-radius: 4px; }

.glide__bullets {
  position: static;
  width: 100%;
  flex-wrap: wrap;
  transform: none;
  justify-content: center; }

.glide__bullet {
  width: 6px;
  height: 6px;
  background-color: #BED1D5;
  margin-bottom: 6px; }

.glide__bullet--active {
  background-color: #49A5A5; }

.error-message {
  color: #A53860; }

#success-message {
  display: block;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 20px;
  padding: 8px;
  border: 1px solid #49A5A5;
  border-radius: 4px;
  color: #197777;
  opacity: 0;
  transition: all 0.2s ease-in; }
  #success-message.show {
    opacity: 1; }
