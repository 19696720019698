// Property Details Page

.property-detail__ref {
    font-weight: 400;
    color: $neutral_color_dark;
}
.property-detail__price {
    color: $secondary_color_dark;
}

.property-detail__features {
    display: flex;
    flex-wrap: wrap;
}

.property-detail__feature {
    display: flex;
    align-items: center;

    width: 50%;
    margin-bottom: 12px;
}

.property-detail__feature-icon {
    height: 24px;
    width: 24px;
    margin-right: 4px;
}
.property-detail__feature-text {
    line-height: 24px;
}

.property-detail__plans-link {
   color: $secondary_color_dark;
}

.property-detail__link {
    @media screen and (min-width: $large_device) {
        display: inline-block;
    }
}

.property-detail__button  {
    display: inline-block;
}

@media screen and (min-width: $large_device) {
    .property-detail__title {
        grid-column: 7 / span 6;
    }

    .property-detail__carousel {
        grid-column: 1 / span 6;
        grid-row: 1 / span 3;
        align-self: start;
    }

    .property-detail__features {
        grid-column: 7 / span 6;
        grid-row: 2;
    }

    .property-detail__description {
        grid-column: 1 / span 6;
        // grid-row: 4;
    }

    .property-detail__highlights {
        grid-column: 7 / span 6;
        grid-row: 2;
        align-self: start;

        display: flex;
        flex-wrap: wrap;

        padding: 10px 20px;
        border-radius: 4px;
        border: 1px solid $neutral_color_light;
    }

    .property-detail__highlights h2 {
        width: 100%;
    }

}

.fixed-cta {
    @extend .shadow;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: white;
    padding: 12px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media screen and (min-width: $large_device) {
        display: none;
    }

    p {
        margin-bottom: 0;
    }
}
