.image-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden
}

.image-wrapper--one-by-one {
  @extend .image-wrapper;
  padding-top: 100%;
}

.image-wrapper--four-by-three {
  @extend .image-wrapper;
  padding-top: 75%;
}

.image-wrapper--three-by-four {
  @extend .image-wrapper;
  padding-top: 133%;
}

.image-wrapper--sixteen-by-nine {
  @extend .image-wrapper;
  padding-top: 56%;
}

.image-wrapper--one-by-one img,
.image-wrapper--four-by-three img,
.image-wrapper--three-by-four img, 
.image-wrapper--sixteen-by-nine img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  // object-position: center;
  transform: translate(-50%, -50%);
}
