.flex-grid {
    display: flex;
    flex-wrap: wrap;
    
    margin-right: -10px;
    margin-left: -10px;
    width: calc(100% + 20px);

    @media screen and (min-width: $large_device) {
        margin-right: -20px;
        margin-left: -20px;
        width: calc(100% + 40px);
    }
}

.flex-item {
    padding: 10px;
    div {
        height: 100px;
        background-color: black;

    }

    @media screen and (min-width: $large_device) {
        padding: 20px;
    }
}
