.property-type__card {
    @extend .shadow;
    text-decoration: none;
    display: flex;
    justify-content: space-between;

    padding: $grid_gap;
    border: 2px solid #fff;
    border-radius: 8px;

    transition: all 0.2s ease-in;

    &:hover { border: 2px solid $primary_color; }
    &:hover .button--tertiary { color: $secondary_color; }
    &:hover .button--tertiary:after { right: 0; }

    @media screen and (min-width: $medium_device) {
        flex-direction: column;
    }
}

.property-type__illustration {
    width: 40%;
    padding-right: $grid_gap / 2;

    @media screen and (min-width: $small_device) {
        padding-right: $grid_gap;
    }

    @media screen and (min-width: $medium_device) {
        padding-right: 0;
        width: 100%;
    }
}

.property-type__name {
    width: 60%;
    padding-left: $grid_gap / 2;
    color: $neutral_color_extra_dark;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: $small_device) {
        padding-left: $grid_gap;
        width: 180px;
    }

    @media screen and (min-width: $medium_device) {
        padding-left: 0;
        width: 100%;
    }

    .button--tertiary {
        align-self: flex-start;
    }
}
