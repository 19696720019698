.property-card .grid {
    @media screen and (min-width: $large_device) {
        grid-row-gap: 20px;
    }
}

.property-card:nth-child(odd) {
    background-color: $neutral_color_extra_light;


    .property-card__image {
        @media screen and (min-width: $large_device) {
            grid-column: 7 / span 6;
            grid-row: 2 / span 6;
        }
    }
    .property-card__image.no-title {
        @media screen and (min-width: $large_device) {
            grid-column: 7 / span 6;
            grid-row: 1 / span 6;
        }
    }
}

.property-card__title {
    text-transform: uppercase;

    span {
        display: block;
        width: 100%;
    }
}

.property-card__headline-locality {
    @media screen and (min-width: $large_device) {
        grid-column: span 6;
    }
}

.property-card__locality {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;

    @media screen and (min-width: $large_device) {
        justify-content: flex-start;
    }

    &-icon,
    &-name {
        margin: 0 4px;
    }
}

.property-card__image {
    .image-wrapper--four-by-three {
        border-radius: 4px;
        filter: drop-shadow(0px 0px 20px $neutral_color_extra_light);
    }

    .property-card__label-wrapper {
        position: absolute;
        top: 20px;
        left: 10px;
        z-index: 10;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .property-card__label {
        align-self: flex-start;
        padding: 8px;
        margin-bottom: 8px;

        font-size: $text_size_small;
        color: #fff;
        background-color: $primary_color_dark;
    }

    .property-card__label--dark {
        @extend .property-card__label;
        background-color: $neutral_color_extra_dark;
    }

    .property-card__label--green {
        @extend .property-card__label;
        background-color: $secondary_color_dark;
    }
}

@media screen and (min-width: $large_device) {
    .property-card__image {
        grid-row: 2 / span 6;
    }
    .property-card__image.no-title {
        grid-row: 1 / span 6;
    }
}

.property-card__price-row  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
        margin-bottom: 0;
    }
}

.property-card__features-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    padding: 10px 20px;
    border-radius: 4px;
    border: 1px solid $neutral_color_light;
}
.property-card__feature {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .property-card__feature-icon {
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }

    .property-card__feature-text {
        font-size: $text_size_small;
        line-height: 24px;
    }

    .property-card__feature-text-description {
        // e.g. bedrooms / bathrooms -> hide them on mobile
        display: none;

        @media screen and (min-width: $small_device) {
            display: initial;
        }
    }
}
