.faqs__item-wrapper {
    @extend .shadow;
    padding: 20px 20px 24px 20px;
    border-radius: 4px;

    &.open .faqs__item-icon { transform: rotate(180deg); }
    &.open .faqs__item-answer-wrapper { height: auto; }
    &.open .faqs__item-answer {
        opacity: 1;
        transform: translateY(0);
    }
}

.faqs__item {
    display: flex;
    justify-content: space-between;

    p {
        margin-bottom: 0;
    }
}

.faqs__item-icon {
    width: 24px;
    height: 24px;
    margin-left: 8px;

    transition: all 0.2s ease-in;
}

.faqs__item-answer-wrapper {
    height: 0;
    overflow: hidden;
}

.faqs__item-answer {
    transition: all 0.2s ease-in;
    transform-origin: top;
    transform: translateY(-100%);
    opacity: 0;
}
