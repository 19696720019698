* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

input, textarea, select { margin: 0; }

button {
  border: none;
  outline: none;
  background-color: transparent;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

img {
  width: 100%;
}
