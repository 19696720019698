@font-face {
  font-family: 'DIN';
  font-weight: 600;
  src: url("/assets/fonts/DINWebPro-Medium.woff") format("woff");
}

@font-face {
  font-family: 'DIN';
  font-weight: 400;
  src: url("/assets/fonts/DINWebPro-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Publico';
  font-weight: 600;
  src: url("/assets/fonts/PublicoText-Semibold.woff") format("woff");
}
/*------------------------------
DEFAULT SETUP
------------------------------*/
html, input, textarea, button, select {
  font-size: $base_size * 1px;
  font-family: 'DIN';
  color: $neutral_color_extra_dark;
}

// This is called heading and not .bold,
// because there'll be instances when for
// a heading instead of using a bold weight
// we might use a different font.
.heading { font-weight: 600; }
.serif { font-family: 'Publico'; }

/*------------------------------
SET BOTTOM MARGIN
------------------------------*/
h1 { margin-bottom: 0.25em; }
h2 { margin-bottom: 0.35em; }
h3 { margin-bottom: 0.45em; }
h4 { margin-bottom: 0.55em; }
h5 { margin-bottom: 0.65em; }
h6, p { margin-bottom: 0.8em; }

/*------------------------------
ALIGNMENT
------------------------------*/
.text--left { text-align: left; }
.text--center { text-align: center; }
.text--right { text-align: right; }

@media screen and (min-width: $small_device) {
  .small-up\:text--left { text-align: left; }
  .small-up\:text--center { text-align: center; }
  .small-up\:text--right { text-align: right; }
}

@media screen and (min-width: $medium_device) {
  .medium-up\:text--left { text-align: left; }
  .medium-up\:text--center { text-align: center; }
  .medium-up\:text--right { text-align: right; }
}

@media screen and (min-width: $large_device) {
  .large-up\:text--left { text-align: left; }
  .large-up\:text--center { text-align: center; }
  .large-up\:text--right { text-align: right; }
}

@media screen and (min-width: $extra_large_device) {
  .extra-large-up\:text--left { text-align: left; }
  .extra-large-up\:text--center { text-align: center; }
  .extra-large-up\:text--right { text-align: right; }
}


/*------------------------------
SET SIZING
------------------------------*/
.text--extra-small {
  font-size: $text_size_extra_small;
  line-height: 1.7;
}

.text--small {
  font-size: $text_size_small;
  line-height: 1.7;
}

p, .text--base {
  font-size: $text_size_base;
  line-height: 1.7;
}

.text--medium {
  font-size: $text_size_medium;
  line-height: 1.2;

  &.heading {
    line-height: 1.3;
  }
}

.text--large {
  font-size: $text_size_large;
  line-height: 1.2;

  &.heading {
    line-height: 1.3;
  }
}

.text--extra-large {
  font-size: $text_size_extra_large;
  line-height: 1.1;

  &.heading {
    line-height: 1.25;
  }
}

.text--display-two {
  font-size: $text_size_display_two;
  line-height: 1.1;

  &.heading {
    line-height: 1.25;
  }
}

.text--display-one {
  font-size: $text_size_display_one;
  line-height: 1.1;

  &.heading {
    line-height: 1.25;
  }
}

@media screen and (min-width: $small_device) {
  .small-up\:text--extra-small {
    font-size: $text_size_extra_small;
    line-height: 1.7;
  }

  .small-up\:text--small {
    font-size: $text_size_small;
    line-height: 1.7;
  }

  .small-up\:text--base {
    font-size: $text_size_base;
    line-height: 1.7;
  }

  .small-up\:text--medium {
    font-size: $text_size_medium;
    line-height: 1.2;

    &.heading {
      line-height: 1.3;
    }
  }

  .small-up\:text--large {
    font-size: $text_size_large;
    line-height: 1.2;

    &.heading {
      line-height: 1.3;
    }
  }

  .small-up\:text--extra-large {
    font-size: $text_size_extra_large;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }

  .small-up\:text--display-two {
    font-size: $text_size_display_two;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }

  .small-up\:text--display-one {
    font-size: $text_size_display_one;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }
}

@media screen and (min-width: $medium_device) {
  .medium-up\:text--extra-small {
    font-size: $text_size_extra_small;
    line-height: 1.7;
  }

  .medium-up\:text--small {
    font-size: $text_size_small;
    line-height: 1.7;
  }

  p, .medium-up\:text--base {
    font-size: $text_size_base;
    line-height: 1.7;
  }

  .medium-up\:text--medium {
    font-size: $text_size_medium;
    line-height: 1.2;

    &.heading {
      line-height: 1.3;
    }
  }

  .medium-up\:text--large {
    font-size: $text_size_large;
    line-height: 1.2;

    &.heading {
      line-height: 1.3;
    }
  }

  .medium-up\:text--extra-large {
    font-size: $text_size_extra_large;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }

  .medium-up\:text--display-two {
    font-size: $text_size_display_two;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }

  .medium-up\:text--display-one {
    font-size: $text_size_display_one;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }
}

@media screen and (min-width: $large_device) {
  .large-up\:text--extra-small {
    font-size: $text_size_extra_small;
    line-height: 1.7;
  }

  .large-up\:text--small {
    font-size: $text_size_small;
    line-height: 1.7;
  }

  p, .large-up\:text--base {
    font-size: $text_size_base;
    line-height: 1.7;
  }

  .large-up\:text--medium {
    font-size: $text_size_medium;
    line-height: 1.2;

    &.heading {
      line-height: 1.3;
    }
  }

  .large-up\:text--large {
    font-size: $text_size_large;
    line-height: 1.2;

    &.heading {
      line-height: 1.3;
    }
  }

  .large-up\:text--extra-large {
    font-size: $text_size_extra_large;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }

  .large-up\:text--display-two {
    font-size: $text_size_display_two;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }

  .large-up\:text--display-one {
    font-size: $text_size_display_one;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }
}

@media screen and (min-width: $extra_large_device) {
  .extra-large-up\:text--extra-small {
    font-size: $text_size_extra_small;
    line-height: 1.7;
  }

  .extra-large-up\:text--small {
    font-size: $text_size_small;
    line-height: 1.7;
  }

  p, .extra-large-up\:text--base {
    font-size: $text_size_base;
    line-height: 1.7;
  }

  .extra-large-up\:text--medium {
    font-size: $text_size_medium;
    line-height: 1.2;

    &.heading {
      line-height: 1.3;
    }
  }

  .extra-large-up\:text--large {
    font-size: $text_size_large;
    line-height: 1.2;

    &.heading {
      line-height: 1.3;
    }
  }

  .extra-large-up\:text--extra-large {
    font-size: $text_size_extra_large;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }

  .extra-large-up\:text--display-two {
    font-size: $text_size_display_two;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }

  .extra-large-up\:text--display-one {
    font-size: $text_size_display_one;
    line-height: 1.1;

    &.heading {
      line-height: 1.25;
    }
  }
}
