/*------------------------------
######      WRAPPER       ######
------------------------------*/
.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: $grid_gap * 1.5 5%;

  &--full-width {
    max-width: 100%;
  }

  @media screen and (min-width: $extra_large_device) {
    padding: $grid_gap * 3 5%;
  }
}

/*------------------------------
######        GRID        ######
------------------------------*/
.grid {
  display: grid;
  gap: $grid_gap;
  grid-template-columns: repeat(12, 1fr);

  @media screen and (min-width: $large_device) {
    gap: $grid_gap * 2;
  }
}

/*------------------------------
ALL DEVICES
------------------------------*/
.grid__item--small {
  grid-column: span 2;
  &-centered {
    grid-column: 6 / span 2;
  }
}

.grid__item--medium {
  grid-column: span 6;
  &-centered {
    grid-column: 4 / span 6;
  }
}

.grid__item--large {
  grid-column: span 8;
  &-centered {
    grid-column: 3 / span 8;
  }
}

.grid__item,
.grid__item--full {
  grid-column: span 12;
}

.grid__item--one-twelfth {
  grid-column: span 1;
  &-first {
    grid-column: 1 / span 1;
    grid-row-start: 1;
  }
}

.grid__item--two-twelfths {
  grid-column: span 2;
  &-first {
    grid-column: 1 / span 2;
    grid-row-start: 1;
  }
}

.grid__item--three-twelfths,
.grid__item--one-forth {
  grid-column: span 3;
  &-first {
    grid-column: 1 / span 3;
    grid-row-start: 1;
  }
}

.grid__item--four-twelfths,
.grid__item--one-third {
  grid-column: span 4;
  &-first {
    grid-column: 1 / span 4;
    grid-row-start: 1;
  }
}

.grid__item--five-twelfths {
  grid-column: span 5;
  &-first {
    grid-column: 1 / span 5;
    grid-row-start: 1;
  }
}

.grid__item--six-twelfths,
.grid__item--two-forths,
.grid__item--one-half {
  grid-column: span 6;
  &-first {
    grid-column: 1 / span 6;
    grid-row-start: 1;
  }
}

.grid__item--seven-twelfths {
  grid-column: span 7;
  &-first {
    grid-column: 1 / span 7;
    grid-row-start: 1;
  }
}

.grid__item--eight-twelfths,
.grid__item--two-thirds {
  grid-column: span 8;
  &-first {
    grid-column: 1 / span 8;
    grid-row-start: 1;
  }
}

.grid__item--nine-twelfths,
.grid__item--three-forths {
  grid-column: span 9;
  &-first {
    grid-column: 1 / span 9;
    grid-row-start: 1;
  }
}

.grid__item--ten-twelfths {
  grid-column: span 10;
  &-first {
    grid-column: 1 / span 10;
    grid-row-start: 1;
  }
}

.grid__item--eleven--twelfths {
  grid-column: span 11;
  &-first {
    grid-column: 1 / span 11;
    grid-row-start: 1;
  }
}

.align--top { align-self: start; }
.align--center { align-self: center; }
.align--bottom { align-self: end; }
.justify--left { justify-self: start; }
.justify--center { justify-self: center; }
.justify--right { justify-self: end; }

/*------------------------------
SMALL DEVICES AND UP
------------------------------*/
@media screen and (min-width: $small_device) {

  .small-up\:grid__item--small {
    grid-column: span 2;
    &-first {
      grid-column: 1 / span 2;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 6 / span 2;
    }
  }

  .small-up\:grid__item--medium {
    grid-column: span 6;
    &-first {
      grid-column: 1 / span 6;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 4 / span 6;
    }
  }

  .small-up\:grid__item--large {
    grid-column: span 8;
    &-first {
      grid-column: 1 / span 8;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 3 / span 8;
    }
  }
  .small-up\:grid__item--full {
    grid-column: span 12;
  }

  .small-up\:grid__item--one-twelfth {
    grid-column: span 1;
    &-first {
      grid-column: 1 / span 1;
      grid-row-start: 1;
    }
  }

  .small-up\:grid__item--two-twelfths {
    grid-column: span 2;
    &-first {
      grid-column: 1 / span 2;
      grid-row-start: 1;
    }
  }

  .small-up\:grid__item--three-twelfths,
  .small-up\:grid__item--one-forth {
    grid-column: span 3;
    &-first {
      grid-column: 1 / span 3;
      grid-row-start: 1;
    }
  }

  .small-up\:grid__item--four-twelfths,
  .small-up\:grid__item--one-third {
    grid-column: span 4;
    &-first {
      grid-column: 1 / span 4;
      grid-row-start: 1;
    }
  }

  .small-up\:grid__item--five-twelfths {
    grid-column: span 5;
    &-first {
      grid-column: 1 / span 5;
      grid-row-start: 1;
    }
  }

  .small-up\:grid__item--six-twelfths,
  .small-up\:grid__item--two-forths,
  .small-up\:grid__item--one-half {
    grid-column: span 6;
    &-first {
      grid-column: 1 / span 6;
      grid-row-start: 1;
    }
  }

  .small-up\:grid__item--seven-twelfths {
    grid-column: span 7;
    &-first {
      grid-column: 1 / span 7;
      grid-row-start: 1;
    }
  }

  .small-up\:grid__item--eight-twelfths,
  .small-up\:grid__item--two-thirds {
    grid-column: span 8;
    &-first {
      grid-column: 1 / span 8;
      grid-row-start: 1;
    }
  }

  .small-up\:grid__item--nine-twelfths,
  .small-up\:grid__item--three-forths {
    grid-column: span 9;
    &-first {
      grid-column: 1 / span 9;
      grid-row-start: 1;
    }
  }

  .small-up\:grid__item--ten-twelfths {
    grid-column: span 10;
    &-first {
      grid-column: 1 / span 10;
      grid-row-start: 1;
    }
  }

  .small-up\:grid__item--eleven-twelfths {
    grid-column: span 11;
    &-first {
      grid-column: 1 / span 11;
      grid-row-start: 1;
    }
  }
  .small-up\:align--top { align-self: start; }
  .small-up\:align--center { align-self: center; }
  .small-up\:align--bottom { align-self: end; }
  .small-up\:justify--left { justify-self: start; }
  .small-up\:justify--center { justify-self: center; }
  .small-up\:justify--right { justify-self: end; }
}


/*------------------------------
MEDIUM DEVICES AND UP
------------------------------*/
@media screen and (min-width: $medium_device) {

  .medium-up\:grid__item--small {
    grid-column: span 2;
    &-first {
      grid-column: 1 / span 2;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 6 / span 2;
    }
  }

  .medium-up\:grid__item--medium {
    grid-column: span 6;
    &-first {
      grid-column: 1 / span 2;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 4 / span 6;
    }
  }

  .medium-up\:grid__item--large {
    grid-column: span 8;
    &-first {
      grid-column: 1 / span 8;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 3 / span 8;
    }
  }

  // this is always center as it takes full width
  .medium-up\:grid__item--full {
    grid-column: span 12;
  }

  .medium-up\:grid__item--one-twelfth {
    grid-column: span 1;
    &-first {
      grid-column: 1 / span 1;
      grid-row-start: 1;
    }
  }

  .medium-up\:grid__item--two-twelfths {
    grid-column: span 2;
    &-first {
      grid-column: 1 / span 2;
      grid-row-start: 1;
    }
  }

  .medium-up\:grid__item--three-twelfths,
  .medium-up\:grid__item--one-forth {
    grid-column: span 3;
    &-first {
      grid-column: 1 / span 3;
      grid-row-start: 1;
    }
  }

  .medium-up\:grid__item--four-twelfths,
  .medium-up\:grid__item--one-third {
    grid-column: span 4;
    &-first {
      grid-column: 1 / span 4;
      grid-row-start: 1;
    }
  }

  .medium-up\:grid__item--five-twelfths {
    grid-column: span 5;
    &-first {
      grid-column: 1 / span 5;
      grid-row-start: 1;
    }
  }

  .medium-up\:grid__item--six-twelfths,
  .medium-up\:grid__item--two-forths,
  .medium-up\:grid__item--one-half {
    grid-column: span 6;
    &-first {
      grid-column: 1 / span 6;
      grid-row-start: 1;
    }
  }

  .medium-up\:grid__item--seven-twelfths {
    grid-column: span 7;
    &-first {
      grid-column: 1 / span 7;
      grid-row-start: 1;
    }
  }

  .medium-up\:grid__item--eight-twelfths,
  .medium-up\:grid__item--two-thirds {
    grid-column: span 8;
    &-first {
      grid-column: 1 / span 8;
      grid-row-start: 1;
    }
  }

  .medium-up\:grid__item--nine-twelfths,
  .medium-up\:grid__item--three-forths {
    grid-column: span 9;
    &-first {
      grid-column: 1 / span 9;
      grid-row-start: 1;
    }
  }

  .medium-up\:grid__item--ten-twelfths {
    grid-column: span 10;
    &-first {
      grid-column: 1 / span 10;
      grid-row-start: 1;
    }
  }

  .medium-up\:grid__item--eleven--twelfths {
    grid-column: span 11;
    &-first {
      grid-column: 1 / span 11;
      grid-row-start: 1;
    }
  }
  .medium-up\:align--top { align-self: start; }
  .medium-up\:align--center { align-self: center; }
  .medium-up\:align--bottom { align-self: end; }
  .medium-up\:justify--left { justify-self: start; }
  .medium-up\:justify--center { justify-self: center; }
  .medium-up\:justify--right { justify-self: end; }
}


/*------------------------------
LARGE DEVICES AND UP
------------------------------*/
@media screen and (min-width: $large_device) {

  .large-up\:grid__item--small {
    grid-column: span 2;
    &-first {
      grid-column: 1 / span 2;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 6 / span 2;
    }
  }

  .large-up\:grid__item--medium {
    grid-column: span 6;
    &-first {
      grid-column: 1 / span 6;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 4 / span 6;
    }
  }

  .large-up\:grid__item--large {
    grid-column: span 8;
    &-first {
      grid-column: 1 / span 8;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 3 / span 8;
    }
  }

  .large-up\:grid__item--full {
    grid-column: span 12;
  }

  .large-up\:grid__item--one-twelfth {
    grid-column: span 1;
    &-first {
      grid-column: 1 / span 1;
      grid-row-start: 1;
    }
  }

  .large-up\:grid__item--two-twelfths {
    grid-column: span 2;
    &-first {
      grid-column: 1 / span 2;
      grid-row-start: 1;
    }
  }

  .large-up\:grid__item--three-twelfths,
  .large-up\:grid__item--one-forth {
    grid-column: span 3;
    &-first {
      grid-column: 1 / span 3;
      grid-row-start: 1;
    }
  }

  .large-up\:grid__item--four-twelfths,
  .large-up\:grid__item--one-third {
    grid-column: span 4;
    &-first {
      grid-column: 1 / span 4;
      grid-row-start: 1;
    }
  }

  .large-up\:grid__item--five-twelfths {
    grid-column: span 5;
    &-first {
      grid-column: 1 / span 5;
      grid-row-start: 1;
    }
  }

  .large-up\:grid__item--six-twelfths,
  .large-up\:grid__item--two-forths,
  .large-up\:grid__item--one-half {
    grid-column: span 6;
    &-first {
      grid-column: 1 / span 6;
      grid-row-start: 1;
    }
  }

  .large-up\:grid__item--seven-twelfths {
    grid-column: span 7;
    &-first {
      grid-column: 1 / span 7;
      grid-row-start: 1;
    }
  }

  .large-up\:grid__item--eight-twelfths,
  .large-up\:grid__item--two-thirds {
    grid-column: span 8;
    &-first {
      grid-column: 1 / span 8;
      grid-row-start: 1;
    }
  }

  .large-up\:grid__item--nine-twelfths,
  .large-up\:grid__item--three-forths {
    grid-column: span 9;
    &-first {
      grid-column: 1 / span 9;
      grid-row-start: 1;
    }
  }

  .large-up\:grid__item--ten-twelfths {
    grid-column: span 10;
    &-first {
      grid-column: 1 / span 10;
      grid-row-start: 1;
    }
  }

  .large-up\:grid__item--eleven--twelfths {
    grid-column: span 11;
    &-first {
      grid-column: 1 / span 11;
      grid-row-start: 1;
    }
  }
  .large-up\:align--top { align-self: start; }
  .large-up\:align--center { align-self: center; }
  .large-up\:align--bottom { align-self: end; }
  .large-up\:justify--left { justify-self: start; }
  .large-up\:justify--center { justify-self: center; }
  .large-up\:justify--right { justify-self: end; }
}


/*------------------------------
EXTRA LARGE DEVICES AND UP
------------------------------*/
@media screen and (min-width: $extra_large_device) {

  .extra-large-up\:grid__item--small {
    grid-column: span 2;
    &-first {
      grid-column: 1 / span 2;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 6 / span 2;
    }
  }

  .extra-large-up\:grid__item--medium {
    grid-column: span 6;
    &-first {
      grid-column: 1 / span 6;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 4 / span 6;
    }
  }

  .extra-large-up\:grid__item--large {
    grid-column: span 8;
    &-first {
      grid-column: 1 / span 8;
      grid-row-start: 1;
    }
    &-centered {
      grid-column: 3 / span 8;
    }
  }

  .extra-large-up\:grid__item--full {
    grid-column: span 12;
  }

  .extra-large-up\:grid__item--one-twelfth {
    grid-column: span 1;
    &-first {
      grid-column: 1 / span 1;
      grid-row-start: 1;
    }
  }

  .extra-large-up\:grid__item--two-twelfths {
    grid-column: span 2;
    &-first {
      grid-column: 1 / span 2;
      grid-row-start: 1;
    }
  }

  .extra-large-up\:grid__item--three-twelfths,
  .extra-large-up\:grid__item--one-forth {
    grid-column: span 3;
    &-first {
      grid-column: 1 / span 3;
      grid-row-start: 1;
    }
  }

  .extra-large-up\:grid__item--four-twelfths,
  .extra-large-up\:grid__item--one-third {
    grid-column: span 4;
    &-first {
      grid-column: 1 / span 4;
      grid-row-start: 1;
    }
  }

  .extra-large-up\:grid__item--five-twelfths {
    grid-column: span 5;
    &-first {
      grid-column: 1 / span 5;
      grid-row-start: 1;
    }
  }

  .extra-large-up\:grid__item--six-twelfths,
  .extra-large-up\:grid__item--two-forths,
  .extra-large-up\:grid__item--one-half {
    grid-column: span 6;
    &-first {
      grid-column: 1 / span 6;
      grid-row-start: 1;
    }
  }

  .extra-large-up\:grid__item--seven-twelfths {
    grid-column: span 7;
    &-first {
      grid-column: 1 / span 7;
      grid-row-start: 1;
    }
  }

  .extra-large-up\:grid__item--eight-twelfths,
  .extra-large-up\:grid__item--two-thirds {
    grid-column: span 8;
    &-first {
      grid-column: 1 / span 8;
      grid-row-start: 1;
    }
  }

  .extra-large-up\:grid__item--nine-twelfths,
  .extra-large-up\:grid__item--three-forths {
    grid-column: span 9;
    &-first {
      grid-column: 1 / span 9;
      grid-row-start: 1;
    }
  }

  .extra-large-up\:grid__item--ten-twelfths {
    grid-column: span 10;
    &-first {
      grid-column: 1 / span 10;
      grid-row-start: 1;
    }
  }

  .extra-large-up\:grid__item--eleven--twelfths {
    grid-column: span 11;
    &-first {
      grid-column: 1 / span 11;
      grid-row-start: 1;
    }
  }

  .extra-large-up\:align--top { align-self: start; }
  .extra-large-up\:align--center { align-self: center; }
  .extra-large-up\:align--bottom { align-self: end; }
  .extra-large-up\:justify--left { justify-self: start; }
  .extra-large-up\:justify--center { justify-self: center; }
  .extra-large-up\:justify--right { justify-self: end; }
}
