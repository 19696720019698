.push--flush { margin: 0; }
.push--top-flush { margin-top: 0; }
.push--bottom-flush { margin-bottom: 0; }
.push--right-flush { margin-right: 0; }
.push--left-flush { margin-left: 0; }
.push--vertical-flush { margin-top: 0; margin-bottom: 0; }
.push--horizontal-flush { margin-left: 0; margin-right: 0; }

.push--small { margin: $grid_gap/5; }
.push--top-small { margin-top: $grid_gap / 5; }
.push--bottom-small { margin-bottom: $grid_gap / 5; }
.push--right-small { margin-right: $grid_gap / 5; }
.push--left-small { margin-left: $grid_gap / 5; }
.push--vertical-small { margin-top: $grid_gap / 5; margin-bottom: $grid_gap / 5;}
.push--horizontal-small { margin-left: $grid_gap / 5; margin-right: $grid_gap / 5; }

.push--medium { margin: $grid_gap / 2; }
.push--top-medium { margin-top: $grid_gap / 2; }
.push--bottom-medium { margin-bottom: $grid_gap / 2; }
.push--right-medium { margin-right: $grid_gap / 2; }
.push--left-medium { margin-left: $grid_gap / 2; }
.push--vertical-medium { margin-top: $grid_gap / 2; margin-bottom: $grid_gap / 2;}
.push--horizontal-medium { margin-left: $grid_gap / 2; margin-right: $grid_gap / 2; }

.push--large { margin: $grid_gap; }
.push--top-large { margin-top: $grid_gap; }
.push--bottom-large { margin-bottom: $grid_gap; }
.push--right-large { margin-right: $grid_gap; }
.push--left-large { margin-left: $grid_gap; }
.push--vertical-large { margin-top: $grid_gap; margin-bottom: $grid_gap;}
.push--horizontal-large { margin-left: $grid_gap; margin-right: $grid_gap; }

.push--extra-large { margin: $grid_gap * 2; }
.push--top-extra-large { margin-top: $grid_gap * 2; }
.push--bottom-extra-large { margin-bottom: $grid_gap * 2; }
.push--right-extra-large { margin-right: $grid_gap * 2; }
.push--left-extra-large { margin-left: $grid_gap * 2; }
.push--vertical-extra-large { margin-top: $grid_gap * 2; margin-bottom: $grid_gap * 2;}
.push--horizontal-extra-large { margin-left: $grid_gap * 2; margin-right: $grid_gap * 2; }


@media screen and (min-width: $small_device) {
  .small-up\:push--flush { margin: 0; }
  .small-up\:push--top-flush { margin-top: 0; }
  .small-up\:push--bottom-flush { margin-bottom: 0; }
  .small-up\:push--right-flush { margin-right: 0; }
  .small-up\:push--left-flush { margin-left: 0; }
  .small-up\:push--vertical-flush { margin-top: 0; margin-bottom: 0; }
  .small-up\:push--horizontal-flush { margin-left: 0; margin-right: 0; }

  .small-up\:push--small { margin: $grid_gap/5; }
  .small-up\:push--top-small { margin-top: $grid_gap / 5; }
  .small-up\:push--bottom-small { margin-bottom: $grid_gap / 5; }
  .small-up\:push--right-small { margin-right: $grid_gap / 5; }
  .small-up\:push--left-small { margin-left: $grid_gap / 5; }
  .small-up\:push--vertical-small { margin-top: $grid_gap / 5; margin-bottom: $grid_gap / 5;}
  .small-up\:push--horizontal-small { margin-left: $grid_gap / 5; margin-right: $grid_gap / 5; }

  .small-up\:push--medium { margin: $grid_gap / 2; }
  .small-up\:push--top-medium { margin-top: $grid_gap / 2; }
  .small-up\:push--bottom-medium { margin-bottom: $grid_gap / 2; }
  .small-up\:push--right-medium { margin-right: $grid_gap / 2; }
  .small-up\:push--left-medium { margin-left: $grid_gap / 2; }
  .small-up\:push--vertical-medium { margin-top: $grid_gap / 2; margin-bottom: $grid_gap / 2;}
  .small-up\:push--horizontal-medium { margin-left: $grid_gap / 2; margin-right: $grid_gap / 2; }

  .small-up\:push--large { margin: $grid_gap; }
  .small-up\:push--top-large { margin-top: $grid_gap; }
  .small-up\:push--bottom-large { margin-bottom: $grid_gap; }
  .small-up\:push--right-large { margin-right: $grid_gap; }
  .small-up\:push--left-large { margin-left: $grid_gap; }
  .small-up\:push--vertical-large { margin-top: $grid_gap; margin-bottom: $grid_gap;}
  .small-up\:push--horizontal-large { margin-left: $grid_gap; margin-right: $grid_gap; }

  .small-up\:push--extra-large { margin: $grid_gap * 2; }
  .small-up\:push--top-extra-large { margin-top: $grid_gap * 2; }
  .small-up\:push--bottom-extra-large { margin-bottom: $grid_gap * 2; }
  .small-up\:push--right-extra-large { margin-right: $grid_gap * 2; }
  .small-up\:push--left-extra-large { margin-left: $grid_gap * 2; }
  .small-up\:push--vertical-extra-large { margin-top: $grid_gap * 2; margin-bottom: $grid_gap * 2;}
  .small-up\:push--horizontal-extra-large { margin-left: $grid_gap * 2; margin-right: $grid_gap * 2; }
}


@media screen and (min-width: $medium_device) {
  .medium-up\:push--flush { margin: 0; }
  .medium-up\:push--top-flush { margin-top: 0; }
  .medium-up\:push--bottom-flush { margin-bottom: 0; }
  .medium-up\:push--right-flush { margin-right: 0; }
  .medium-up\:push--left-flush { margin-left: 0; }
  .medium-up\:push--vertical-flush { margin-top: 0; margin-bottom: 0; }
  .medium-up\:push--horizontal-flush { margin-left: 0; margin-right: 0; }

  .medium-up\:push--small { margin: $grid_gap/5; }
  .medium-up\:push--top-small { margin-top: $grid_gap / 5; }
  .medium-up\:push--bottom-small { margin-bottom: $grid_gap / 5; }
  .medium-up\:push--right-small { margin-right: $grid_gap / 5; }
  .medium-up\:push--left-small { margin-left: $grid_gap / 5; }
  .medium-up\:push--vertical-small { margin-top: $grid_gap / 5; margin-bottom: $grid_gap / 5;}
  .medium-up\:push--horizontal-small { margin-left: $grid_gap / 5; margin-right: $grid_gap / 5; }

  .medium-up\:push--medium { margin: $grid_gap / 2; }
  .medium-up\:push--top-medium { margin-top: $grid_gap / 2; }
  .medium-up\:push--bottom-medium { margin-bottom: $grid_gap / 2; }
  .medium-up\:push--right-medium { margin-right: $grid_gap / 2; }
  .medium-up\:push--left-medium { margin-left: $grid_gap / 2; }
  .medium-up\:push--vertical-medium { margin-top: $grid_gap / 2; margin-bottom: $grid_gap / 2;}
  .medium-up\:push--horizontal-medium { margin-left: $grid_gap / 2; margin-right: $grid_gap / 2; }

  .medium-up\:push--large { margin: $grid_gap; }
  .medium-up\:push--top-large { margin-top: $grid_gap; }
  .medium-up\:push--bottom-large { margin-bottom: $grid_gap; }
  .medium-up\:push--right-large { margin-right: $grid_gap; }
  .medium-up\:push--left-large { margin-left: $grid_gap; }
  .medium-up\:push--vertical-large { margin-top: $grid_gap; margin-bottom: $grid_gap;}
  .medium-up\:push--horizontal-large { margin-left: $grid_gap; margin-right: $grid_gap; }

  .medium-up\:push--extra-large { margin: $grid_gap * 2; }
  .medium-up\:push--top-extra-large { margin-top: $grid_gap * 2; }
  .medium-up\:push--bottom-extra-large { margin-bottom: $grid_gap * 2; }
  .medium-up\:push--right-extra-large { margin-right: $grid_gap * 2; }
  .medium-up\:push--left-extra-large { margin-left: $grid_gap * 2; }
  .medium-up\:push--vertical-extra-large { margin-top: $grid_gap * 2; margin-bottom: $grid_gap * 2;}
  .medium-up\:push--horizontal-extra-large { margin-left: $grid_gap * 2; margin-right: $grid_gap * 2; }
}


@media screen and (min-width: $large_device) {
  .large-up\:push--flush { margin: 0; }
  .large-up\:push--top-flush { margin-top: 0; }
  .large-up\:push--bottom-flush { margin-bottom: 0; }
  .large-up\:push--right-flush { margin-right: 0; }
  .large-up\:push--left-flush { margin-left: 0; }
  .large-up\:push--vertical-flush { margin-top: 0; margin-bottom: 0; }
  .large-up\:push--horizontal-flush { margin-left: 0; margin-right: 0; }

  .large-up\:push--small { margin: $grid_gap/5; }
  .large-up\:push--top-small { margin-top: $grid_gap / 5; }
  .large-up\:push--bottom-small { margin-bottom: $grid_gap / 5; }
  .large-up\:push--right-small { margin-right: $grid_gap / 5; }
  .large-up\:push--left-small { margin-left: $grid_gap / 5; }
  .large-up\:push--vertical-small { margin-top: $grid_gap / 5; margin-bottom: $grid_gap / 5;}
  .large-up\:push--horizontal-small { margin-left: $grid_gap / 5; margin-right: $grid_gap / 5; }

  .large-up\:push--medium { margin: $grid_gap / 2; }
  .large-up\:push--top-medium { margin-top: $grid_gap / 2; }
  .large-up\:push--bottom-medium { margin-bottom: $grid_gap / 2; }
  .large-up\:push--right-medium { margin-right: $grid_gap / 2; }
  .large-up\:push--left-medium { margin-left: $grid_gap / 2; }
  .large-up\:push--vertical-medium { margin-top: $grid_gap / 2; margin-bottom: $grid_gap / 2;}
  .large-up\:push--horizontal-medium { margin-left: $grid_gap / 2; margin-right: $grid_gap / 2; }

  .large-up\:push--large { margin: $grid_gap; }
  .large-up\:push--top-large { margin-top: $grid_gap; }
  .large-up\:push--bottom-large { margin-bottom: $grid_gap; }
  .large-up\:push--right-large { margin-right: $grid_gap; }
  .large-up\:push--left-large { margin-left: $grid_gap; }
  .large-up\:push--vertical-large { margin-top: $grid_gap; margin-bottom: $grid_gap;}
  .large-up\:push--horizontal-large { margin-left: $grid_gap; margin-right: $grid_gap; }

  .large-up\:push--extra-large { margin: $grid_gap * 2; }
  .large-up\:push--top-extra-large { margin-top: $grid_gap * 2; }
  .large-up\:push--bottom-extra-large { margin-bottom: $grid_gap * 2; }
  .large-up\:push--right-extra-large { margin-right: $grid_gap * 2; }
  .large-up\:push--left-extra-large { margin-left: $grid_gap * 2; }
  .large-up\:push--vertical-extra-large { margin-top: $grid_gap * 2; margin-bottom: $grid_gap * 2;}
  .large-up\:push--horizontal-extra-large { margin-left: $grid_gap * 2; margin-right: $grid_gap * 2; }
}


@media screen and (min-width: $extra_large_device) {
  .extra-large-up\:push--flush { margin: 0; }
  .extra-large-up\:push--top-flush { margin-top: 0; }
  .extra-large-up\:push--bottom-flush { margin-bottom: 0; }
  .extra-large-up\:push--right-flush { margin-right: 0; }
  .extra-large-up\:push--left-flush { margin-left: 0; }
  .extra-large-up\:push--vertical-flush { margin-top: 0; margin-bottom: 0; }
  .extra-large-up\:push--horizontal-flush { margin-left: 0; margin-right: 0; }

  .extra-large-up\:push--small { margin: $grid_gap/5; }
  .extra-large-up\:push--top-small { margin-top: $grid_gap / 5; }
  .extra-large-up\:push--bottom-small { margin-bottom: $grid_gap / 5; }
  .extra-large-up\:push--right-small { margin-right: $grid_gap / 5; }
  .extra-large-up\:push--left-small { margin-left: $grid_gap / 5; }
  .extra-large-up\:push--vertical-small { margin-top: $grid_gap / 5; margin-bottom: $grid_gap / 5;}
  .extra-large-up\:push--horizontal-small { margin-left: $grid_gap / 5; margin-right: $grid_gap / 5; }

  .extra-large-up\:push--medium { margin: $grid_gap / 2; }
  .extra-large-up\:push--top-medium { margin-top: $grid_gap / 2; }
  .extra-large-up\:push--bottom-medium { margin-bottom: $grid_gap / 2; }
  .extra-large-up\:push--right-medium { margin-right: $grid_gap / 2; }
  .extra-large-up\:push--left-medium { margin-left: $grid_gap / 2; }
  .extra-large-up\:push--vertical-medium { margin-top: $grid_gap / 2; margin-bottom: $grid_gap / 2;}
  .extra-large-up\:push--horizontal-medium { margin-left: $grid_gap / 2; margin-right: $grid_gap / 2; }

  .extra-large-up\:push--large { margin: $grid_gap; }
  .extra-large-up\:push--top-large { margin-top: $grid_gap; }
  .extra-large-up\:push--bottom-large { margin-bottom: $grid_gap; }
  .extra-large-up\:push--right-large { margin-right: $grid_gap; }
  .extra-large-up\:push--left-large { margin-left: $grid_gap; }
  .extra-large-up\:push--vertical-large { margin-top: $grid_gap; margin-bottom: $grid_gap;}
  .extra-large-up\:push--horizontal-large { margin-left: $grid_gap; margin-right: $grid_gap; }

  .extra-large-up\:push--extra-large { margin: $grid_gap * 2; }
  .extra-large-up\:push--top-extra-large { margin-top: $grid_gap * 2; }
  .extra-large-up\:push--bottom-extra-large { margin-bottom: $grid_gap * 2; }
  .extra-large-up\:push--right-extra-large { margin-right: $grid_gap * 2; }
  .extra-large-up\:push--left-extra-large { margin-left: $grid_gap * 2; }
  .extra-large-up\:push--vertical-extra-large { margin-top: $grid_gap * 2; margin-bottom: $grid_gap * 2;}
  .extra-large-up\:push--horizontal-extra-large { margin-left: $grid_gap * 2; margin-right: $grid_gap * 2; }
}
