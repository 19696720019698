/*------------------------------
GENERAL SETUP
------------------------------*/

label {
    display: block;
}
input, select { height: 45px;}
textarea { height: 250px; }

input, textarea {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid $borders_color;

  &:focus {
    border: 1px solid $focus_color;
  }
}


.form__select {
    width: 100%;
    overflow-x: hidden;
    border: 1px solid $borders_color;
    background: url('/assets/icons/chevron-down.svg');
    background-repeat: no-repeat;
    background-position: right;
}

select {
    background-color: transparent;
    padding: 4px 8px;
    width: 100%;
    border: none;
    -moz-appearance: none;
    appearance: none;
    option {
        max-width: 50px;
    }
}

/*------------------------------
CHECKBOX INPUT
------------------------------*/
.form__checkbox {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  background-color: transparent;

  & input {
    opacity: 0;
    flex-grow: 0;
    width: 35px;
    height: 45px;
  }

  .checkmark {
    width: 32px;
    height: 44px;
    padding: 12px 12px 12px 0;

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-color: $off_color;
      transition: all 0.2s ease-in-out;
    }
  }

  input:checked ~ .checkmark:after {
    background-size: 20px;
    background-color: green;
    background-image: url('/check.svg');
  }
}

/*------------------------------
RADIO INPUT
------------------------------*/
.form__radio {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;

  & input {
    width: 35px;
    height: 45px;
    flex-grow: 0;
    opacity: 0;
  }

  .radio {
    display: block;
    width: 20px;
    height: 44px;
    padding: 12px 0;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: $off_color;
      transition: all 0.2s ease-in-out;
    }
  }

  input:checked ~ .radio:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-color: $secondary_color;
  }

  input:checked ~ .radio:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
  }
}

/*------------------------------
TOGGLE INPUT
------------------------------*/
.form__toggle {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: transparent;

  & input {
    opacity: 0;
    flex-grow: 0;
    width: 45px;
    height: 45px;
  }

  .track {
    display: block;
    width: 35px;
    height: 45px;
    padding: 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    &:after {
      content: '';
      display: block;
      width: 30px;
      height: 10px;
      margin-top: 7px;
      border-radius: 7px;
      border: 1px solid $neutral_color_light;
      background-color: $neutral_color_extra_light;
      transition: all 0.2s ease-in-out;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 18px;
      height: 18px;
      background-color: white;
      border: 1px solid $neutral_color_light;
      border-radius: 100%;
      transition: all 0.2s ease-in-out;
    }
  }

  input:checked ~ .track:after {
    background-color: $secondary_color_extra_light;
  }

  input:checked ~ .track:before {
    transform: translateX(15px);
    background-color: $secondary_color;
    border: 1px solid $secondary_color_dark;
  }
}
