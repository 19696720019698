.hide { display: none; }
.show { display: block; }

@media screen and (min-width: $small_device) {
    .small-up\:hide { display: none; }
    .small-up\:show { display: block; }
}

@media screen and (min-width: $medium_device) {
    .medium-up\:hide { display: none; }
    .medium-up\:show { display: block; }
}

@media screen and (min-width: $large_device) {
    .large-up\:hide { display: none; }
    .large-up\:show { display: block; }
}

@media screen and (min-width: $extra_large_device) {
    .extra-large-up\:hide { display: none; }
    .extra-large-up\:show { display: block; }
}
