button {
  cursor: pointer;
}

.more-questions__person-name {
    margin-bottom: 0;
}
.more-questions__job-title {
    color: $neutral_color_dark;
}

// Glide Carousel
.glide__slide img {
    @extend .shadow;
    border-radius: 4px;
}

.glide__bullets {
    position: static;
    width: 100%;
    flex-wrap: wrap;
    transform: none;
    justify-content: center;
}
.glide__bullet {
    width: 6px;
    height: 6px;
    background-color: $neutral_color_light;
    margin-bottom: 6px;
}
.glide__bullet--active {
    background-color: $secondary_color;
}


// contact forms
.error-message {
    @extend .text--small;
    color: $primary_color;
}

#success-message {
    display: block;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: $grid_gap;
    padding: 8px;
    border: 1px solid $secondary_color;
    border-radius: 4px;
    color: $secondary_color_dark;
    opacity: 0;
    transition: all 0.2s ease-in;
    &.show {
        opacity: 1;
    }
}
