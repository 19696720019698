button:focus,
button:active,
select:focus, 
select:active {
    outline: none;
    border: none;
}

.button {
    display: inline-block;
    min-width: 140px;
    height: 45px;
    padding: 0 $grid_gap / 2;
    font-size: $text_size_base;
    font-weight: 700;
    line-height: 45px;
    text-decoration: none;
    text-align: center;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;


    &--large {
        @extend .text--medium;
        min-width: 250px;
        height: 60px;
        line-height: 60px;
    }
}

.button--primary {
    @extend .button;
    color: #fff;
    background-color: $primary_color;
    border: 2px solid $primary_color;

    &:hover {
        background-color: $primary_color_dark;
        border-color: $primary_color_dark;
    }

    &-outline {
        @extend .button;
        background-color: transparent;
        border: 2px solid $primary_color;
        color: $primary_color;

        &:hover {
            background-color: $primary_color;
            color: #fff;
        }
    }
}

.button--secondary {
    @extend .button;
    color: $secondary_color_extra_dark;
    background-color: $secondary_color;
    border: 2px solid $secondary_color;

    &:hover {
        background-color: $secondary_color_light;
        border-color: $secondary_color_light;
    }

    &-outline {
        @extend .button;
        background-color: transparent;
        border: 2px solid $secondary_color;

        &:hover {
            background-color: $secondary_color;
        }
    }
}

.button--tertiary {
    font-size: $text_size_small;
    color: $neutral_color_dark;
    text-align: left;

    display: block;
    padding: 0px 36px 0px 0;
    position: relative;

    transition: all 0.2s ease-in;

    &:hover {
        color: $secondary_color;
    }

    &:after {
        display: block;
        content: url('/assets/icons/icon-arrow-right.svg');
        position: absolute;
        top: 0;
        bottom: 0;
        right: 8px;
        transition: all 0.2s ease-in;
    }

    &:hover:after {
        right: 0;
    }
}

.button-with-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;

    svg { margin-right: 4px;}
}
