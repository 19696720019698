.brt-wrapper {
    display: flex;
    align-items: center;

    width: 100%;
    height: 45px;
    border-radius: 23px;

    border: 1px solid $neutral_color;
    background-color: $neutral_color_extra_light;
}

.brt__input {
    position: relative;
    width: 50%;
    height: 100%;

    input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;

        opacity: 0;
        width: 100%;
        height: 100%;
    }

    span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 23px;
        transform: translateX(100%) scale(1.1);
        background-color: $secondary_color;
        transition: all 0.2s cubic-bezier(.35,-0.14,.31,1.32);
    }

    input:checked + span {
        color: #fff;
        transform: translateX(0) scale(1.1);

        // transform: scale(1.1);
    }

    label {
        @extend .heading;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        height: 45px;
        line-height: 45px;
        width: 100%;
        text-align: center;
    }
}
